import React from 'react'

import './ReadingItem.css'

function ReadingItem(props) {
  return (
    <div className="reading-item">
      <h3 translate='no' className="reading-item__title">{props.title}</h3>
      <div className="reading-item__rules">
        <p translate='no' className="reading-item__rule">{props.rule1}</p>
        <p translate='no' className="reading-item__rule">{props.rule2}</p>
      </div>
      <div className={props.className}>
        <p translate='no' className="reading-item__image-exapmle">{props.imgExample}</p>
      </div>
      <div className="reading-item__examples">
        <p translate='no' className="reading-item__example">{props.example1}</p>
        <p translate='no' className="reading-item__example">{props.example2}</p>
        <p  translate='no' className="reading-item__example">{props.example3}</p>

        <div className="reading-item__sentense-examples" >
          <p translate='no' className="reading-item__example">{props.example4}</p>
          <p translate='no' className="reading-item__example">{props.example5}</p>
          <p translate='no' className="reading-item__example">{props.example6}</p>
        </div>
      </div>
    </div>
  )
}

export default ReadingItem
