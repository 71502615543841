import React, { useState } from 'react'
import './ServiceItem.css'

function ServiceItem(props) {
  const [cardFrontSurface, setCardSurface] = useState(true)

  function rotateHandler() {
    setCardSurface(!cardFrontSurface)
  }

  return (
    <div className="item">
      <div className="item__body">
        <div
          className={
            cardFrontSurface ? 'item__front' : 'item__front item__front-rotate'
          }
        >
          <h3 translate='no' className="item__title">{props.title}</h3>
          <div className={props.imageClassname} />
          <div className="item__priceList-container">
            <button className="item__details-btn" onClick={rotateHandler}>
              Подробнее
            </button>
          </div>
        </div>

        <div className={cardFrontSurface ? 'item__back' : 'item__back-rotate'}>
          <div className="item__back-container">
            <div className="item__mini-logo"></div>
            <p translate='no' className="item__books">Программа на выбор</p>
            <p translate='no'className="item__book">{props.book1}</p>
            <p translate='no' className="item__book">{props.book2}</p>
            <p translate='no' className="item__book last-book">{props.book3}</p>

            <p translate='no' className="item__books">Ключевые элементы урока</p>
            <p translate='no' className="item__activities">
              {props.description}
            </p>
           
           
            <button
              className="item__details-btn back-details-btn"
              onClick={rotateHandler}
            >
              На главную
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceItem
