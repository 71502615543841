import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import './FeedbackForm.css'

function FeedbackForm() {
  const [isValidNameInput, setIsValidNameInput] = React.useState(true)
  const [isValidEmailInput, setIsValidEmailInput] = React.useState(true)
  const [isValidTextInput, setIsValidTextInput] = React.useState(true)

  const [inputNameError, setInputNameError] = React.useState('')
  const [inputEmailError, setInputEmailError] = React.useState('')
  const [inputTextError, setInputTextError] = React.useState('')

  const [nameReady, setNameReady] = React.useState(false)
  const [emailReady, setEmailReady] = React.useState(false)
  const [textReady, setTextReady] = React.useState(false)
  const [checkboxReady, setCheckboxReady] = React.useState(false)

	const handleChangeNameInputError = (e) => {
    if (e.target.validity.valid) {
      setInputNameError('')
      setIsValidNameInput(true)
      setNameReady(true)
    } else {
      setInputNameError(e.target.validationMessage)
      setIsValidNameInput(false)
      setNameReady(false)
    }
  }

  const handleChangeEmailInputError = (e) => {
    if (e.target.validity.valid) {
      setInputEmailError('')
      setIsValidEmailInput(true)
      setEmailReady(true)
    } else {
      setInputEmailError(e.target.validationMessage)
      setIsValidEmailInput(false)
      setEmailReady(false)
    }
  }

  const handleChangeTextInputError = (e) => {
    if (e.target.validity.valid) {
      setInputTextError('')
      setIsValidTextInput(true)
      setTextReady(true)
    } else {
      setInputTextError(e.target.validationMessage)
      setIsValidTextInput(false)
      setTextReady(false)
    }
  }

  const checkboxOnChange = (e) => {
    if (e.target.checked === true) {
      setCheckboxReady(true)
    } else {
      setCheckboxReady(false)
    }
  }

  return (
    <div className="fb-form__wrapper">
      <h3 translate="no" className="fb-form__title">
        Форма обратной&nbsp;связи
      </h3>
      <form
        noValidate
        className="fb-form"
        method="post"
        name="feedback-form"
        action="https://english.moscow/feedback.php"
      >
        <fieldset className="feedback__fieldset">
          <div className="feedback__name-stars-space">
            <div class="rating-area">
              <input type="radio" id="star-5" name="rating" value="5" />
              <label for="star-5" title="Оценка «5»"></label>
              <input type="radio" id="star-4" name="rating" value="4" />
              <label for="star-4" title="Оценка «4»"></label>
              <input type="radio" id="star-3" name="rating" value="3" />
              <label for="star-3" title="Оценка «3»"></label>
              <input type="radio" id="star-2" name="rating" value="2" />
              <label for="star-2" title="Оценка «2»"></label>
              <input type="radio" id="star-1" name="rating" value="1" />
              <label for="star-1" title="Оценка «1»"></label>
            </div>
            <div className="feedback__name-email-space">
              <label className="feedback__label">
                <input
                  type="text"
                  name="name"
                  className={
                    isValidNameInput
                      ? 'feedback__name-input'
                      : 'feedback__name-input input_error'
                  }
                  id="feedback-name"
                  placeholder="Имя"
                  minLength="2"
                  maxLength="30"
                  required
                  onChange={handleChangeNameInputError}
                />
                <span className="feedback__error-text">
                  {inputNameError || ''}
                </span>
              </label>

              <label className="feedback__label">
                <input
                  translate="no"
                  type="email"
                  name="email"
                  className={
                    isValidEmailInput
                      ? 'feedback__name-input'
                      : 'feedback__name-input input_error'
                  }
                  id="feedback-email"
                  placeholder="Email&nbsp;*"
                  required
                  pattern="^.*@.+$"
                  onChange={handleChangeEmailInputError}
                />
                <span className="feedback__error-text">
                  {inputEmailError || ''}
                </span>
              </label>
            </div>
          </div>
          <label className="feedback__label">
            <textarea
              className={
                isValidTextInput
                  ? 'feedback__text-input'
                  : 'feedback__text-input input_error'
              }
              id="feedback-text"
              name="text"
              type="text"
              placeholder="Ваши впечатления о&nbsp;занятиях"
              minLength="10"
              maxLength="1000"
              onChange={handleChangeTextInputError}
              required
            />
            <span className="feedback__error-text feedback__error-text-area">
              {inputTextError || ''}
            </span>
          </label>
          <div className="feedback__agree-box">
            <input
              className="feedback__agree-checkbox"
              type="checkbox"
              onChange={checkboxOnChange}
            />
            <p translate="no" className="feedback__agree-span">
              {' '}
              Я даю согласие на обработку моих персональных данных в соответствии с <NavLink className="feedback__inner-link" to="/privacy-policy">политикой конфиденциальности.</NavLink></p>
          </div>
          <p className="feedback__agree-span feedback__agree-span-email">
            {' '}
            Bаш e-mail не будет размещен в блоке отзывов, а служит только для
            связи.
          </p>
        </fieldset>
        <button
          className="feedback__send-button"
          // onClick={sendFeedback}
          disabled={
            nameReady && emailReady && textReady && checkboxReady
              ? ''
              : 'disabled'
          }
        >
          Отправить
        </button>
      </form>
    </div>
  )
}

export default FeedbackForm
