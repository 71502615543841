import React from 'react'

import './SecondaryHeader.css'
import BackHeaderButton from '../../Elements/BackHeaderButton/BackHeaderButton'
import HeaderLogoArea from '../../Elements/HeaderLogoArea/HeaderLogoArea'

function SecondaryHeader() {
  return (
    <div className="secondary-header section">
      <div className="section-container secondary-header__container"> 
      <HeaderLogoArea />
      <BackHeaderButton />
      </div>
    </div>
  )
}

export default SecondaryHeader