import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType2 from '../../../Sections/GrammarIntroType2/GrammarIntroType2'
import KidsRetellingContainer from '../../../EducationSections/KidsRetellingContainer/KidsRetellingContainer'

function KidsRetellingPage() {
  return (
    <div className='page'>
    <EducationalHeader />
    <SecondaryContent>
     <GrammarIntroType2 
     title ="SHORT TEXTS FOR RETELLING"
     />
     <KidsRetellingContainer />
    </SecondaryContent>
     <SecondaryFooter />
 </div>
  )
}

export default KidsRetellingPage