import React from 'react'

import './MainVideo.css'

function MainVideo() {
  return (
    <section className="main-video section">
      <div className="section-container t-b-paddings">
			<div className='main-video__container'>
        
        <div>
            <p translate='no'>Vocabulary unit (in English)</p>
            <iframe className='main-video_video-item'  src="https://rutube.ru/play/embed/b7b780fb2c9974e85a3715f01c6be0fe/"  allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
            </div>
            <div>
              <p translate='no'>Interesting facts (in Russian)</p>
            <iframe className='main-video_video-item'  src="https://rutube.ru/play/embed/022326f8551d8cb8e6bce58e9618ec35/"  allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
            </div>
            
            
          </div>

      
      </div>
    </section>
  )
}

export default MainVideo