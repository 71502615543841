import React from 'react'

import './SecondaryContent.css'

function SecondaryContent(props) {
  return (
    <div className='secondary-content'>
      {props.children}
    </div>
  )
}

export default SecondaryContent