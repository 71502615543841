import React from 'react'

import './WeatherRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function WeatherRow() {
  /* for blue */
  //   const blueTrack = document.getElementById('blueID')
  //   const blue = require('./blue.mp3')
  //   function bluePlay() {
  //     blueTrack.play()
  //   }
  //   /* for green */
  //   const greenTrack = document.getElementById('greenID')
  //   const green = require('./green.mp3')
  //   function greenPlay() {
  //     greenTrack.play()
  //   }

  //   /* for red */
  //   const redTrack = document.getElementById('redID')
  //   const red = require('./red.mp3')
  //   function redPlay() {
  //     redTrack.play()
  //   }
  //   /* for orange*/
  //   const orangeTrack = document.getElementById('orangeID')
  //   const orange = require('./orange.mp3')
  //   function orangePlay() {
  //     orangeTrack.play()
  //   }

  //   /* for white */
  //   const whiteTrack = document.getElementById('whiteID')
  //   const white = require('./white.mp3')
  //   function whitePlay() {
  //     whiteTrack.play()
  //   }

  //   /* for yellow */
  //   const yellowTrack = document.getElementById('yellowID')
  //   const yellow = require('./yellow.mp3')
  //   function yellowPlay() {
  //     yellowTrack.play()
  //   }

  //   /* for pink */
  //   const pinkTrack = document.getElementById('pinkID')
  //   const pink = require('./pink.mp3')
  //   function pinkPlay() {
  //     pinkTrack.play()
  //   }

  //   /* for brown */
  //   const brownTrack = document.getElementById('brownID')
  //   const brown = require('./brown.mp3')
  //   function brownPlay() {
  //     brownTrack.play()
  //   }

  //   /* for black */
  //   const blackTrack = document.getElementById('blackID')
  //   const black = require('./black.mp3')
  //   function blackPlay() {
  //     blackTrack.play()
  //   }

  //   /* for purple */
  //   const purpleTrack = document.getElementById('purpleID')
  //   const purple = require('./purple.mp3')
  //   function purplePlay() {
  //     purpleTrack.play()
  //   }

  return (
    <DataSlider>
      {/* for sunny */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_sunny">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                sunny
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              What is the weather like? It&nbsp;is&nbsp;sunny.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for rainy */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_rainy">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                rainy
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;rainy.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for stormy */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_stormy">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                stormy
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;stormy.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for cloudy */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_cloudy">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                cloudy
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;cloudy.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* snowy */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_snowy">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                snowy
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;snowy.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>
            {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

         {/* for hot */}
         <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_hot">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
               hot
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;hot.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for cold */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_cold">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                cold
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;cold.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

        {/* for foggy */}
        <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_foggy">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                foggy
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;foggy.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>


       

        

       {/* for windy */}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_windy">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                windy
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;windy.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      
       {/* for sing*/}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_frosty">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                frosty
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is the weather like? It&nbsp;is&nbsp;frosty.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

     
    </DataSlider>
  )
}
export default WeatherRow
