import React from 'react'

import './ReadingStep5.css'
import ReadingLevelContainer from '../ReadingLevelContainer/ReadingLevelContainer'
import ReadingItem from '../ReadingItem/ReadingItem'

function ReadingStep5() {
  return (
    <ReadingLevelContainer>
      <ReadingItem 
        title="Step 41"
        className="reading-item__image reading-item__image_41"
        rule1="1. Буква «o» перед «m», «n», «v» [ʌ], но&nbsp;«Donald» [dɒnld] "
        rule2="2. Повторим «step 34»: буква «o» перед «th» тоже [ʌ]"
        example1='&bull;&nbsp;to come &bull;&nbsp;some &bull;&nbsp;my mom'
        example2='&bull;&nbsp;a son &bull;&nbsp;won &bull;&nbsp;month'
        example3='&bull;&nbsp;to love &bull;&nbsp;gloves &bull;&nbsp;a dove'
        example4='&bull;&nbsp;My mom loves me.'
        example5='&bull;&nbsp;I have gloves.'
        example6='&bull;&nbsp;My son won.'
        imgExample="Donald will come to London."
        />

        <ReadingItem
        title="Step 42"
        className="reading-item__image reading-item__image_42"
        rule1="1. Буквосочетание «wr» [w]"
        rule2="2. Запомни имя «Sean»  [ʃɔːn]"
        example1='&bull;&nbsp;to write &bull;&nbsp;a writer ' 
        example2='&bull;&nbsp;wrong &bull;&nbsp;wrote'
        example3='&bull;&nbsp;a wrist &bull;&nbsp;to wrap'
        example4='&bull;&nbsp;Let us wrap a present.'
        example5='&bull;&nbsp;Write to me.'
        example6='&bull;&nbsp;It is wrong.'
        imgExample="Sean is a writer."
         />
        
        <ReadingItem 
        title="Step 43"
        className="reading-item__image reading-item__image_43"
        rule1="1. Буквосочетание «wh» перед буквой «o» [h]"
        rule2="2. Буквосочетание «wh» в остальных случаях [w]"
        example1='&bull;&nbsp;whole &bull;&nbsp;who &bull;&nbsp;whose'
        example2='&bull;&nbsp;a whale &bull;&nbsp;while &bull;&nbsp;when'
        example3='&bull;&nbsp;what &bull;&nbsp;why &bull;&nbsp;white'
        example4='&bull;&nbsp;Who is he?'
        example5='&bull;&nbsp;What is it?'
        example6='&bull;&nbsp;Why are you sad?'
        imgExample="The whale is blue and white."
        
        />
        <ReadingItem 
        title="Step 44"
        className="reading-item__image reading-item__image_44"
        rule1="1. Буквa «h» в «ah» и «oh» в  конце слова не читается"
        rule2="2. Запомни вариации слова «verandah» / «veranda» "
        example1='&bull;&nbsp;ah &bull;&nbsp;oh &bull;&nbsp;a cheetah'
        example2='&bull;&nbsp;Sarah&bull;&nbsp;Hannah &bull;&nbsp;a verandah'
        example4="&bull;&nbsp;Hannah is Sarah's friend."
        example5='&bull;&nbsp;Oh! I see Sarah and Hannah.'
        example6='&bull;&nbsp;We are on the verandah.'
        imgExample="Ah! The cheetah is very fast."
        />

        <ReadingItem 
        title="Step 45"
        className="reading-item__image reading-item__image_45"
        rule1="1. Буква «y» в закрытом слоге [i]"
        rule2="2. Сравни слова: «typical» [ˈtɪpɪkl] и&nbsp;«type» [taip]"
        example1='&bull;&nbsp;a myth &bull;&nbsp;a lynx '
        example2='&bull;&nbsp;a system &bull;&nbsp;a mystery'
        
        example4='&bull;&nbsp;I like the lynx.'
        example5='&bull;&nbsp;I read myths.'
        example6="&bull;&nbsp;I am reading a mystery."
        imgExample="tigers and a lynx"
        />
         <ReadingItem 
        title="Step 46"
        className="reading-item__image reading-item__image_46"
        rule1="1. Буквосочетания «ir», «er», «ur» и «yr» в закрытом слоге [ɜː]"
        rule2="2. Слово «girl» начинается со звука [g]"
        example1='&bull;&nbsp;a girl &bull;&nbsp;a bird &bull;&nbsp;Byrd'
        example2='&bull;&nbsp;to turn &bull;&nbsp;her &bull;&nbsp;fur'
        example3='&bull;&nbsp;to burn&bull;&nbsp;a herd &bull;&nbsp;a fir tree'

        example4='&bull;&nbsp;Mr Byrd has a bird.'
        example5='&bull;&nbsp;A girl likes her bird.'
        example6='&bull;&nbsp;My cat has white fur.'
        imgExample="They are fir trees."
        
        />

        <ReadingItem 
        title="Step 47"
        className="reading-item__image reading-item__image_47"
        rule1="1. Буквосочетание «ture» [tʃə]"
        rule2="2. Запомни «a culture vulture» — ценитель культуры"
        example1='&bull;&nbsp;a picture &bull;&nbsp; a culture'
        example2='&bull;&nbsp;a vulture &bull;&nbsp;future '
        example3='&bull;&nbsp;furniture &bull;&nbsp;a sculpture'

        example4=' &bull;&nbsp;I like this picture.'
        example5=' &bull;&nbsp;Bill is a culture vulture.'
        example6='&bull;&nbsp;I can see a sculpture.'
        imgExample="They work on sculptures."
        
        />
        <ReadingItem 
        title="Step 48"
        className="reading-item__image reading-item__image_48"
        rule1="1. Буквосочетания «tion» [ʃ(ə)n]"
        rule2="2. И буквосочетание «sion» [ʃ(ə)n]"
        example1="&bull;&nbsp;an action &bull;&nbsp;a fiction"
        example2="&bull;&nbsp;a station &bull;&nbsp;a section"
        example3="&bull;&nbsp;a mansion &bull;&nbsp;a session"
        example4="&bull;&nbsp;a good profession"
        example5="&bull;&nbsp;a good location"
        example6="&bull;&nbsp;Don't mention it!"
        imgExample="They have a big mansion."
        />
        <ReadingItem 
        title="Step 49"
        className="reading-item__image  reading-item__image_49"
        rule1="1. Буква «o» перед «ld», «st» и «lt» [əʊ]"
        rule2="2. Запомни слова: «сost» [kɔst] и&nbsp;«lost»&nbsp;[lɔst]"
        example1='&bull;&nbsp;old &bull;&nbsp;sold &bull;&nbsp;gold &bull;&nbsp;cold'
        example2='&bull;&nbsp;most &bull;&nbsp;to post &bull;&nbsp;a bolt'
        example4='Cравни слова: '
        example5='&bull;&nbsp;«cod» [kɔd] и «cold» [kəʊld]'
        example6='&bull;&nbsp;«pot» [pɔt] и «post» [pəʊst]'
        imgExample="a post office"
        />
        <ReadingItem 
        title="Step 50"
        className="reading-item__image reading-item__image_50"
        rule1="1. Буквосочетание «tch» как и «ch» [tʃ]"
        rule2="2. Запомни слово «of» [ɔv]"
        example1='&bull;&nbsp;a match &bull;&nbsp;a kitchen &bull;&nbsp;to fetch'
        example2='&bull;&nbsp;a ditch &bull;&nbsp;to watch &bull;&nbsp;to catch'
        example4='&bull;&nbsp;Cats catch mice.'
        example6="&bull;&nbsp;It is in front of the kitchen."
        example5='&bull;&nbsp;Fetch my watch.'
        imgExample="They are watching TV."
 />
        
        
       
    </ReadingLevelContainer>
  )
}

export default ReadingStep5