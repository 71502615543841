import React from 'react'

import './TestIntro.css'
import SecondaryTitle from '../../Elements/SecondaryTitle/SecondaryTitle'

function TestIntro() {
  return (
    <div>
      <SecondaryTitle title="THE ENTRY TEST" />
      <div className="test-intro__main">
        <div className="test-intro__text">
          <p className="test-intro__text">Дорогие ученики! </p>
          <p className="test-intro__text">
            Мы можем начать ваше обучение с самых основ: с фраз приветствия и
            правил чтения. Однако, если у вас уже есть определенный багаж
            знаний, перед тем как приступить к занятиям, мы проведем
            индивидуальное тестирование для определения подходящего для вас
            уровня.
          </p>
          
        </div>
        <div className="test-intro__photo" />
      </div>
    </div>
  )
}

export default TestIntro
