import React from 'react'
import { NavLink } from 'react-router-dom'

import './CardsMenuPopup.css'

function CardsMenuPopup(props) {
  return (
    <section className={props.className}>
      <div className="cards-menu-popup__container">
        <h2 translate="no" className="cards-menu-popup__title">
          Полезный материал:
        </h2>
        

        <ul className="cards-menu-popup__list">
        <li className="cards-menu-popup__item">
            <div className="cards-menu-popup__logo-decor" />
            <NavLink
              translate="no"
              to="/active-voice"
              className="menu-cards__link"
            >
              Active voice
            </NavLink>
          </li>
          <li className="cards-menu-popup__item">
            <div className="cards-menu-popup__logo-decor" />
            <NavLink
              translate="no"
              to="/irregular-verbs"
              className="menu-cards__link"
            >
              Irregular verbs
            </NavLink>
          </li>
          <li className="cards-menu-popup__item">
            <div className="cards-menu-popup__logo-decor" />
            <NavLink
              translate="no"
              to="/proverbs-and-quotes"
              className="menu-cards__link"
            >
              Proverbs and quotes
            </NavLink>
          </li>
          
           <li className="cards-menu-popup__item">
				<div className="cards-menu-popup__logo-decor"/>
          <NavLink
            translate="no"
            to="/binomials"
            className="menu-cards__link"
          >
            Binomials
          </NavLink>
        </li>

				<li className="cards-menu-popup__item">
				<div className="cards-menu-popup__logo-decor"/>
        <NavLink
          translate="no"
          to="/linkers"
          className="menu-cards__link"
        >
          Linkers
        </NavLink> 
				</li>

        <h3 translate="no"className='menu-section-subtitle' >
          Для детей (1-4 классов)
        </h3>
        <li className="cards-menu-popup__item">
				<div className="cards-menu-popup__logo-decor"/>
        <NavLink
          translate="no"
          to="/preschool-reading"
          className="menu-cards__link"
        >
          Тренажёр по чтению
        </NavLink> 
				</li>
        
        
        <li className="cards-menu-popup__item">
				<div className="cards-menu-popup__logo-decor"/>
        <NavLink
          translate="no"
          to="/kids-vocabulary"
          className="menu-cards__link"
        >
          Cловарный запас
        </NavLink> 
				</li>
        <div className="cards-menu-popup__vocabulary-menu">
          <NavLink translate="no" className="menu-cards__link menu-cards__link_parts" to="/kids-vocabulary">Часть 1</NavLink>
          <NavLink translate="no" className="menu-cards__link menu-cards__link_parts" to="/kids-vocabulary-2">Часть 2</NavLink>
          <NavLink translate="no" className="menu-cards__link menu-cards__link_parts" to="/kids-vocabulary-3">Часть 3</NavLink>
        </div>
        
        <li className="cards-menu-popup__item">
				<div className="cards-menu-popup__logo-decor"/>
        <NavLink
          translate="no"
          to="/kids-retelling"
          className="menu-cards__link"
        >
          Тексты на пересказ
        </NavLink> 
				</li>
        </ul>

        
        
        <button
          translate="no"
          className="cards-menu-popup__close-btn"
          onClick={props.closeMenuHandle}
        >
          &#x2716;
        </button>
        <button
          translate="no"
          className="cards-menu-popup__close-btn-words"
          onClick={props.closeMenuHandle}
        >
          на главную
        </button>

        {/* <div className='cards-menu-popup__install-box'>
        <p translate='no' className='cards-menu-popup__install-text'>
          Если текущая подборка может оказаться для вас полезной, вы можете установить данное приложение на экран в телефоне.
        </p>
       </div>  */}
      </div>
    </section>
  )
}

export default CardsMenuPopup
