import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './DataSlider.css'

function DataSlider(props) {
  // const number = window.innerWidth > 1000 ? 3: window.innerWidth > 500 ? 2 : 1;
  let [number, setNumber] = React.useState(getInitialNumber())
  let resizeTimeout;

  React.useEffect(() => {
    window.addEventListener("resize", resizeThrottler)
  })

  const resizeThrottler = ()  => {
    if ( !resizeTimeout ) {
      resizeTimeout = setTimeout(function() {
        resizeTimeout = null;
        setNumber(getInitialNumber())
      }, 300);
    }
  }

  function getInitialNumber() {
    if (window.innerWidth >= 1000) {
      return 3
    } else if (window.innerWidth >= 550) {
      return 2
    } else {
      return 1
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: number,
    slidesToScroll: 1,
    arrows: true,
  }

  return <Slider {...settings}>{props.children}</Slider>
}

export default DataSlider