import React from 'react'

import './ReadingStep2.css'
import ReadingLevelContainer from '../ReadingLevelContainer/ReadingLevelContainer'
import ReadingItem from '../ReadingItem/ReadingItem'

function ReadingStep2() {
  return (
    <ReadingLevelContainer>
        <ReadingItem
        title="Step 11"
        className="reading-item__image reading-item__image_11"
        rule1="1. Гласная «&#8544;i» в открытом слоге [ai]"
        rule2="2. Гласная «e» в конце неодносложного слова не читается"
        example1='&bull;&nbsp;Hi &bull;&nbsp;&#8544; &bull;&nbsp;Mike &bull;&nbsp;to tide' 
        example2='&bull;&nbsp;a bike &bull;&nbsp;a pike &bull;&nbsp;to ride'
        example3='&bull;&nbsp;to like &bull;&nbsp;a kite &bull;&nbsp;a spike'
        example5='&bull;&nbsp;Hi! &#8544; like kites.'
        example6='&bull;&nbsp;Hi! &#8544; like to ride a bike.'
        example4='&bull;&nbsp;Hi! &#8544; am Mike.'
        imgExample="kites"
         />
        <ReadingItem 
        title="Step 12"
        className="reading-item__image reading-item__image_12"
        rule1="1. Буква «e» в конце односложного слова [i:] / [i]"
        rule2="2. Буквосочетание «sh» [ʃ]"
        example1='&bull;&nbsp;we &bull;&nbsp;me &bull;&nbsp;he &bull;&nbsp;she &bull;&nbsp;be'
        example2='&bull;&nbsp;a ship &bull;&nbsp;a sheep &bull;&nbsp;a shell'
        example3='&bull;&nbsp;a fish &bull;&nbsp;a dish &bull;&nbsp;a wish'
        example4='&bull;&nbsp;We can see a ship.'
        example5='&bull;&nbsp;We like shells.'
        example6='&bull;&nbsp;She can see me.'
        imgExample="a ship"
        
        />
        <ReadingItem 
        title="Step 13"
        className="reading-item__image reading-item__image_13"
        rule1="1. Буквосочетание «ck» [k]"
        rule2="2. Запомни  слово «has» [hæz]"
        example1='&bull;&nbsp;a сlock &bull;&nbsp;a sock &bull;&nbsp;a lock'
        example2='&bull;&nbsp;a duck &bull;&nbsp;luck &bull;&nbsp;to cluck'
        example3='&bull;&nbsp;Nick a stick &bull;&nbsp;to pick'
        example4='&bull;&nbsp;Nick has a clock.'
        example5='&bull;&nbsp;Nick has socks.'
        example6='&bull;&nbsp;Nick has ducks.'
        imgExample="ducks"
        
        />
        <ReadingItem 
        title="Step 14"
        className="reading-item__image reading-item__image_14"
        rule1="1. Буквосочетание «al» [ɔːl]"
        rule2="2. Запомни слово «the» [ðə] / [ðiː] перед&nbsp;гласным звуком"
        example1='&bull;&nbsp;tall &bull;&nbsp;small &bull;&nbsp;a hall'
        example2='&bull;&nbsp;a mall &bull;&nbsp;to call'
        example3='&bull;&nbsp;salt &bull;&nbsp;all &bull;&nbsp;a stall'
        example4='&bull;&nbsp;Tom is tall.'
        example5='&bull;&nbsp;Tom is in the hall.'
        example6='&bull;&nbsp;Tom is in the mall.'
        imgExample="a shopping mall"
        />
        <ReadingItem 
        title="Step 15"
        className="reading-item__image reading-item__image_15"
        rule1="1. Гласная  «Aa» в открытом слоге [ei]"
        rule2="2. Запомни слово «have» [hæv]"
        example1='&bull;&nbsp;Dave &bull;&nbsp;a cave &bull;&nbsp;to save'
        example2='&bull;&nbsp;a name &bull;&nbsp;same &bull;&nbsp;tame'
        example3='&bull;&nbsp;a lane &bull;&nbsp;a cane '

        example4='Сравни пары слов:'
        example5=' &bull;&nbsp;a man — a mane;'
        example6='&bull;&nbsp;Sam — same.'
        imgExample="Dave is in the cave."
        />
         <ReadingItem 
        title="Step 16"
        className="reading-item__image reading-item__image_16"
        rule1="1. Буквосочетание «ar» [ɑː]"
        rule2="2. Запомни слово «are» [ɑː(r)]"
        example1='&bull;&nbsp;a star &bull;&nbsp;a car &bull;&nbsp;dark'
        example2='&bull;&nbsp;Mark &bull;&nbsp;Clark &bull;&nbsp;a lark'
        example3='&bull;&nbsp;in the park &bull;&nbsp;smart'
        example4='&bull;&nbsp;Mark is in the park. '
        example5='&bull;&nbsp;Mark has a car.'
        example6='&bull;&nbsp;The cars are dark red.'
        imgExample="Mark is in the car."
        
        />

        <ReadingItem 
        title="Step 17"
        className="reading-item__image reading-item__image_17"
        rule1="1. Гласная «Oo» в закрытом слоге [əʊ]"
        rule2="2. Запомни слова «to» [tuː] и «do» [duː]"
        example1='&bull;&nbsp;a note &bull;&nbsp;to hope &bull;&nbsp;a rope'
        example2='&bull;&nbsp;no &bull;&nbsp;so &bull;&nbsp;a tone &bull;&nbsp;a stone'
        example3='&bull;&nbsp;a globe &bull;&nbsp;home &bull;&nbsp;to go'
        example4='Сравни пары слов:'
        example5=' &bull;&nbsp;a cod - a code;'
        example6='&bull;&nbsp;to hop - a hope.'
        imgExample="So, we need to go home."
        
        />
        <ReadingItem 
        title="Step 18"
        className="reading-item__image reading-item__image_18"
        rule1="1. Буква «Yy» перед гласной [j]"
        rule2="2. Запомни словa «you» [ju:] и «young» [jʌŋ]"
        example1='&bull;&nbsp;Yana &bull;&nbsp;yet &bull;&nbsp;to yell'
        example2='&bull;&nbsp;yes &bull;&nbsp;young &bull;&nbsp;you'

        example4='&bull;&nbsp;You are in the hall.'
        example5='&bull;&nbsp;You are in the park.'
        example6='&bull;&nbsp;You are in the pool.'
        imgExample="Yana is young."
        />
        <ReadingItem 
        title="Step 19"
        className="reading-item__image  reading-item__image_19"
        rule1="1. Гласная «Yy»  в открытом слоге под ударением [ai]"
        rule2="2. Запомни слово «friend»  [frend]"
        example1='&bull;&nbsp;my &bull;&nbsp;sly &bull;&nbsp;the sky'
        example2='&bull;&nbsp;Kyle &bull;&nbsp;shy &bull;&nbsp;a spy'
        example4='&bull;&nbsp;Kyle is my friend.'
        example5='&bull;&nbsp;Kyle is shy.'
        example6='&bull;&nbsp;My kite is in the sky.'
        imgExample="A star is in the sky."
        />
        <ReadingItem 
        title="Step 20"
        className="reading-item__image reading-item__image_20"
        rule1="1. Буква «Yy» в открытом слоге не под ударением [ai]"
        rule2="2. Запомни слово «family» [ˈfæməli]"
        example1='&bull;&nbsp;happy &bull;&nbsp;funny &bull;&nbsp;tasty'
        example2='&bull;&nbsp;angry &bull;&nbsp;sorry &bull;&nbsp;silly'
        example4='&bull;&nbsp;My family is happy.'
        example5='&bull;&nbsp;Nelly is funny.'
        example6='&bull;&nbsp;I am sorry.'
        imgExample="Wendy has a candy."
        
        />
    </ReadingLevelContainer>
  )
}

export default ReadingStep2