import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType2 from '../../../Sections/GrammarIntroType2/GrammarIntroType2'
import ActiveVoiceContainer from '../../../EducationSections/GrammarContainer/GrammarContainer'

function ActiveVoicePage() {
  return (
    <div className='page'>
    <EducationalHeader />
    <SecondaryContent>
     <GrammarIntroType2 
     title ="THE ACTIVE VOICE"
     />
     <ActiveVoiceContainer />
    </SecondaryContent>
     <SecondaryFooter />
 </div>
  )
}

export default ActiveVoicePage