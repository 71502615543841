import React from 'react'
import PublicOffer from '../../Sections/PublicOffer/PublicOffer'

function PublicOfferPage() {
  return (
    <div className='page'>
      <PublicOffer />
    </div>
  )
}

export default PublicOfferPage