import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType2 from '../../../Sections/GrammarIntroType2/GrammarIntroType2'
import IrregularVerbsContainer from '../../../EducationSections/IrregularVerbsContainer/IrregularVerbsContainer'

function IrregularVerbsPage() {
  return (
    <div className='page'>
       <EducationalHeader />
       <SecondaryContent>
        <GrammarIntroType2 
        title="Irregular verbs"
        /> 
        <IrregularVerbsContainer />
       </SecondaryContent>
        <SecondaryFooter />
    </div>
  )
}

export default IrregularVerbsPage