import React from 'react'

import './SliderTitle.css';

function SliderTitle(props) {
  return (
    <h2 className='slider-title'>{props.title}</h2>
  )
}

export default SliderTitle