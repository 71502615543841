import React from 'react'

import './KidsVocabularyContainer3.css';

// import DataSlider from '../../Elements/DataSlider/DataSlider';
// import KidsSlide from '../KidsSlide/KidsSlide';


function KidsVocabularyContainer3() {
  return (
    <div className='Kids-vocabulary-container'>
    
    
            
  
    </div>
  )
  
}

export default KidsVocabularyContainer3
