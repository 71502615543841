import React from 'react'

import './FruitRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function FruitRow() {

  // /* for kiwi */
  const kiwi = require('./kiwi.mp3')
  function kiwiPlay() {
    const kiwiTrack = document.getElementById('kiwiID')
    kiwiTrack.play()
  }

  /* for coconut */
  const coconut = require('./coconut.mp3')
  function coconutPlay() {
    const coconutTrack = document.getElementById('coconutID')
    coconutTrack.play()
  }

  /* for apple */
  const apple = require('./apple.mp3')
  function applePlay() {
    const appleTrack = document.getElementById('appleID')
    appleTrack.play()
  }

  /* for pear */
  const pear = require('./pear.mp3')
  function pearPlay() {
    const pearTrack = document.getElementById('pearID')
    pearTrack.play()
  }

  /* for pineapple */
  const pineapple = require('./pineapple.mp3')
  function pineapplePlay() {
    const pineappleTrack = document.getElementById('pineappleID')
    pineappleTrack.play()
  }

  /* for banana */
  const banana = require('./banana.mp3')
  function bananaPlay() {
    const bananaTrack = document.getElementById('bananaID')
    bananaTrack.play()
  }

  /* for plum */
  const plum = require('./plum.mp3')
  function plumPlay() {
    const plumTrack = document.getElementById('plumID')
    plumTrack.play()
  }

  /* for mandarin */
  const mandarin = require('./mandarin.mp3')
  function mandarinPlay() {
    const mandarinTrack = document.getElementById('mandarinID')
    mandarinTrack.play()
  }

  /* for peach */
  const peach = require('./peach.mp3')
  function peachPlay() {
    const peachTrack = document.getElementById('peachID')
    peachTrack.play()
  }

  /* for apricot */
  const apricot = require('./apricot.mp3')
  function apricotPlay() {
    const apricotTrack = document.getElementById('apricotID')
    apricotTrack.play()
  }

  return (
    <DataSlider>
       {/* for coconut */}
       <div>
        <div className="kids-slide kids-slide__image_coconut" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
           a coconut
            </p>
            <button className="kids-slide__sound-btn box-shadow " onClick={coconutPlay} />
          </div>
          <audio id="coconutID">
            <source src={coconut} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for kiwi */}
      <div>
        <div className="kids-slide kids-slide__image_kiwi" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
            kiwis
            </p>
            <button className="kids-slide__sound-btn box-shadow " onClick={kiwiPlay} />
          </div>
          <audio id="kiwiID">
            <source src={kiwi} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for apple */}
      <div>
        <div className="kids-slide kids-slide__image_apple" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
            an apple
            </p>
            <button className="kids-slide__sound-btn box-shadow" onClick={applePlay} />
          </div>
          <audio id="appleID">
            <source src={apple} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for pear */}
      <div>
        <div className="kids-slide kids-slide__image_pear" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
             a pear
            </p>
            <button className="kids-slide__sound-btn box-shadow " onClick={pearPlay} />
          </div>
          <audio id="pearID">
            <source src={pear} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for pineapple */}
      <div>
        <div className="kids-slide kids-slide__image_pineapple" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
           a pineapple
            </p>
            <button className="kids-slide__sound-btn box-shadow " onClick={pineapplePlay} />
          </div>
          <audio id="pineappleID">
            <source src={pineapple} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for banana */}
      <div>
        <div className="kids-slide kids-slide__image_banana" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
            bananas
            </p>
            <button className="kids-slide__sound-btn box-shadow" onClick={bananaPlay} />
          </div>
          <audio id="bananaID">
            <source src={banana} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for plum */}
      <div>
        <div className="kids-slide kids-slide__image_plum" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow " translate="no">
            plums
            </p>
            <button className="kids-slide__sound-btn box-shadow " onClick={plumPlay} />
          </div>
          <audio id="plumID">
            <source src={plum} type="audio/mp3" />
          </audio>
        </div>
      </div>

      <div>
        <div className="kids-slide kids-slide__image_mandarin" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
            tangerines
            </p>
            <button className="kids-slide__sound-btn box-shadow" onClick={mandarinPlay} />
          </div>
          <audio id="mandarinID">
            <source src={mandarin} type="audio/mp3" />
          </audio>
        </div>
      </div> 

      {/* for peach */}
      <div>
        <div className="kids-slide kids-slide__image_peach" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
              peaches
            </p>
            <button className="kids-slide__sound-btn box-shadow" onClick={peachPlay} />
          </div>
          <audio id="peachID">
            <source src={peach} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for apricot */}
      <div>
        <div className="kids-slide kids-slide__image_apricot" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text box-shadow" translate="no">
            apricots
            </p>
            <button className="kids-slide__sound-btn box-shadow " onClick={apricotPlay} />
          </div>
          <audio id="apricotID">
            <source src={apricot} type="audio/mp3" />
          </audio>
        </div>
      </div>
    </DataSlider>
  )
}
export default FruitRow
