import React from 'react'
import './LinkersSlide.css';

function LinkersSlide(props) {
  return (
    <div className='linkers-slide'>
      <h3 className='linkers-slide__title'translate="no" >{props.title}</h3>
      <div className={props.slideClassName}>
        <p className='linkers-slide__example' translate="no">{props.example}</p>
      </div>
      <div className='linkers-slide__list'>
        <p className='linkers-slide__list-item' translate="no" >{props.text1}</p>
        <p className='linkers-slide__list-item' translate="no" >{props.text2}</p>
        <p className='linkers-slide__list-item' translate="no">{props.text3}</p>
        <p className='linkers-slide__list-item' translate="no">{props.text4}</p>
        <p className='linkers-slide__list-item' translate="no">{props.text5}</p>
      </div>
      
      
    </div>
  )
}

export default LinkersSlide