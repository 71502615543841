import React from 'react'

import './PresentPerfectTense.css'
import GrammarAccordion from '../../../GrammarAccordion/GrammarAccordion'
import TenseRule from '../../../GrammarRules/TenseRule/TenseRule'

function PresentPerfectTense() {
  return (
    <GrammarAccordion title="Present Perfect">
      <TenseRule
        statement1="I / you / we / they + have + verb(III) "
        statement2="He / she / it  + has + verb(III)"
        statementEg1="I've already done my project."
        statementEg2="He has recently called."

        negative1="I / you / we / they + haven't verb(III) "
        negative2="He / she / it  + hasn't verb(III)"
        negativeEg1="I haven't done my project yet."
        negativeEg2="He still hasn't called."

        question1=" have + I / you / we / they + verb(III) "
        question2=" has + he / she / it  + verb(III)"
        questionEg1="Have many projects have you done so far?"
        questionEg2="Has he called lately?"

        marker1="already;"
        marker2="just;"
        marker3="yet, still (for negations);"
        marker4="never;"
        marker5="ever;"
        marker6="recently;"
        marker7="lately;"
        marker8="so far;"
        marker9="today, this week/term/etc.;"
        marker10="How long (for state verbs);"
        marker11="since, for;"
        marker12="How many, how much."
      >
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          actions which finished and the result is visible in the present;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          actions with state verbs which started in the past and continue up to the present;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          actions which happened at an unstated time in the past;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          actions which happened within a specific period which is not over at the moment of speaking;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          to announce a piece of news.
        </li>
      </TenseRule>
    </GrammarAccordion>
  )
}

export default PresentPerfectTense
