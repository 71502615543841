import React from 'react'

import './GrammarContainer.css'
import PresentSimpleTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentSimpleTense/PresentSimpleTense'
import PresentContinuousTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PastContinuousTense/PastContinuousTense'
import PresentPerfectTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentPerfectTense/PresentPerfectTense'
import PresentPerfectContinuousTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentPerfectContinuousTense/PresentPerfectContinuousTense'
import PastSimpleTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentSimpleTense/PresentSimpleTense'
import PastContinuousTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PastContinuousTense/PastContinuousTense'
import PastPerfectTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentPerfectTense/PresentPerfectTense'
import PastPerfectContinuousTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentPerfectContinuousTense/PresentPerfectContinuousTense'
import FutureSimpleTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentSimpleTense/PresentSimpleTense'
import FutureContinuousTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PastContinuousTense/PastContinuousTense'
import FuturePerfectTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentPerfectTense/PresentPerfectTense'
import FuturePerfectContinuousTense from '../Grammar/GrammarBlocks/ActiveVoiceTenses/PresentPerfectContinuousTense/PresentPerfectContinuousTense'

function GrammarContainer() {
  return (
    <div className="grammar-container">
      <PresentSimpleTense />
      <PresentContinuousTense />
      <PresentPerfectTense />
      <PresentPerfectContinuousTense />
      <PastSimpleTense />
      <PastContinuousTense />
      <PastPerfectTense />
      <PastPerfectContinuousTense />
      <FutureSimpleTense />
      <FutureContinuousTense />
      <FuturePerfectTense />
      <FuturePerfectContinuousTense />
      <span className="grammar-accordion__line-decor" />
    </div>
  )
}

export default GrammarContainer
