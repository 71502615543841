import React from 'react'

import './TenseRule.css'

function TenseRule(props) {
  return (
    <div className="tense-rule">
      {/* <p className="tense-rule__title" translate="no">
        THE RULES
      </p> */}
      <ul className="tense-rule__table">
        <li className="tense-rule__table-item table-item-1">
          <p className="tense-rule__subtitle" translate="no">
            Using
          </p>
          <ul className="tense-rule__table-box table-box-1">
            {props.children}
          </ul>
        </li>

        <li className="tense-rule__table-item table-item-2 ">
          <p className="tense-rule__subtitle" translate="no">
            Sentences
          </p>
          <ul className="tense-rule__table-box table-box-2">
            <li className="tense-rule__rule-box">
              <p className="tense-rule__rule">(+)</p>
              <div>
                <p className="tense-rule__rule" translate="no">
                  {props.statement1}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                  e.g. {props.statementEg1}
                </p>
                <p className="tense-rule__rule" translate="no">
                  {props.statement2}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                  e.g. {props.statementEg2}
                </p>
                <p className="tense-rule__rule" translate="no">
                  {props.statement3}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                 {props.statementEg3}
                </p>
              </div>
            </li>
            <li className="tense-rule__rule-box">
              <p className="tense-rule__rule">(-)</p>
              <div>
                <p className="tense-rule__rule" translate="no">
                  {props.negative1}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                  e.g. {props.negativeEg1}
                </p>
                <p className="tense-rule__rule" translate="no">
                  {props.negative2}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                  e.g. {props.negativeEg2}
                </p>
                <p className="tense-rule__rule" translate="no">
                  {props.negative3}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                 {props.negativeEg3}
                </p>
              </div>
            </li>
            <li className="tense-rule__rule-box">
              <p className="tense-rule__rule">(?)</p>
              <div>
                <p className="tense-rule__rule" translate="no">
                  {props.question1}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                  e.g. {props.questionEg1}
                </p>
                
                <p className="tense-rule__rule" translate="no">
                  {props.question2}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                  e.g. {props.questionEg2}
                </p>
                <p className="tense-rule__rule" translate="no">
                  {props.question3}
                </p>
                <p className="tense-rule__rule-eg" translate="no">
                 {props.questionEg3}
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li className="tense-rule__table-item table-item-3">
          <p className="tense-rule__subtitle" translate="no">
            Markers
          </p>
          <div className='tense-rule__picture' />
          <ul className="tense-rule__table-box table-box-3">
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker1}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker2}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker3}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker4}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker5}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker6}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker7}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker8}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker9}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker10}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker11}
            </li>
            <li translate="no" className="tense-rule__marker-item">
              <span className="using__tick">&#183; </span>
              {props.marker12}
            </li>
          </ul>
        </li>
      </ul>
      {/* <p
        className="tense-rule__title tense-rule__title-before-pr"
        translate="no"
      >
        PRACTICE
      </p> */}
    </div>
  )
}

export default TenseRule
