import React from 'react'

import './BinomialsContainer.css';
import BinomialsSlide from '../BinomialsSlide/BinomialsSlide'

function BinomialsContainer(props) {
  return (
    <div className={props.className}>
     
      <a translate='no' className="v-s_nav-link" href="#binomials-list"> &#10513; Перейти к полному списку&#8201;/&#8201;To the full list </a>

      <div className='s-v__slides-container'>
      <BinomialsSlide
            title="peace and quiet"
            exp="тишина и покой"
            slideClassName="binomial__image binomial__image_1"
            text1="We rented a nice chalet in the countryside for a week to enjoy some peace and quiet."
          />
          <BinomialsSlide
            title="chalk and cheese"
            exp="разные, как небо и земля"
            slideClassName="binomial__image binomial__image_2"
            text1="Despite being twin brothers, Michael and Alan are different. They are like cheese and chalk."
          />
          <BinomialsSlide
            title="hustle and bustle"
            exp="шум и гам"
            slideClassName="binomial__image binomial__image_3"
            text1="The atmosphere of Christmas eve was in the town which was filled with hustle and bustle."
          />
          <BinomialsSlide
            title="bright and early"
            exp="ни свет ни заря"
            slideClassName="binomial__image binomial__image_4"
            text1="The travellers woke up bright and early to set off as soon as it was possible."
          />
          <BinomialsSlide
            title="safe and sound"
            exp="целы и невредимы"
            slideClassName="binomial__image binomial__image_5"
            text1="There was a harsh avalanche in the mountains. All the climbers returned safe and sound though."
          />
          <BinomialsSlide
            title="odds and ends"
            exp="всякая всячина, ненужный хлам"
            slideClassName="binomial__image binomial__image_6"
            text1="Sorting out my attic, I decided to get rid of all odds and ends, which I'd been storing for ages."
          />
          <BinomialsSlide
            title="neat and tidy"
            exp="чисто и аккуратно"
            slideClassName="binomial__image binomial__image_7"
            text1="Megan's house looks spotlessly clean. She does a great deal to make it look neat and tidy."
          />
          <BinomialsSlide 
          title="pros and cons"
          exp="плюсы и минусы, за и против"
          slideClassName="binomial__image binomial__image_8"
          text1="It was a difficult decision to make. We had to consider all pros and cons."
          />

          <BinomialsSlide
            title="by and large"
            exp="в общем и целом"
            slideClassName="binomial__image binomial__image_9"
            text1="I can't say  that I agree with every word, but the article was worth reading by and large. I liked it."
          />
          <BinomialsSlide
            title="every now and then"
            exp="время от времени"
            slideClassName="binomial__image binomial__image_10"
            text1="That boutique is pretty costy. However, they offer gоod  sales and bargains every now and then. "
          />
      </div>

      <div className='v-s_list' id="binomials-list">
            <p translate="no" className='v-s_list-title_binomials'>THE MOST COMMON BINOMIALS WITH "and"</p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>1.</span> Born and bred —  <span span className='v-s_list-transl'>родился и вырос;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>2.</span> Pick and choose — <span span className='v-s_list-transl'>привередливо перебирать;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>3.</span> Plain and simple — <span span className='v-s_list-transl'>коротко и ясно;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>4.</span> Peace and quiet  — <span span className='v-s_list-transl'>тишина и покой;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>5.</span> Hustle and bustle — <span span className='v-s_list-transl'> шум и гам;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>6.</span> High and low — <span span className='v-s_list-transl'>повсюду;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>7.</span> Neat and tidy — <span span className='v-s_list-transl'>чисто и аккуратно;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>8.</span> Bright and early — <span span className='v-s_list-transl'> с утра пораньше;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>9.</span> Safe and sound — <span span className='v-s_list-transl'> в целости и сохраности;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>10.</span> Out and about — <span span className='v-s_list-transl'>в хорошей форме;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>11.</span> Odds and ends  — <span span className='v-s_list-transl'>всякая всячина;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>12.</span> Pros and cons — <span span className='v-s_list-transl'>за и против, преимущества и недостатки; </span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>13.</span> By and large — <span span className='v-s_list-transl'> в общем и целом;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>14.</span> Dine and wine — <span span className='v-s_list-transl'>угощать;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>15.</span> Far and wide — <span span className='v-s_list-transl'>вдоль и поперёк, повсеместно;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>16.</span> Chalk and cheese — <span span className='v-s_list-transl'>разные;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>17.</span> Every now and there — <span span className='v-s_list-transl'>время от времени;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>18.</span> First and foremost — <span span className='v-s_list-transl'>прежде всего;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>19.</span> Leaps and bounds  — <span span className='v-s_list-transl'>стремительно;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>20.</span> Days and nights — <span span className='v-s_list-transl'>круглосуточно, непрерывно;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>21.</span> Give and take — <span span className='v-s_list-transl'>взаимные уступки;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>22.</span> Rest and recreation — <span span className='v-s_list-transl'>отдых и развлечения;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>23.</span> Heart and soul — <span span className='v-s_list-transl'>душа и сердце;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>24.</span> Part and parcel  — <span span className='v-s_list-transl'>неотъемлемая часть;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>25.</span> Tried and true  — <span span className='v-s_list-transl'>надёжный и проверенный;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>26.</span> Short and sweet  — <span span className='v-s_list-transl'>коротко и ясно;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>27.</span> Live and learn  — <span span className='v-s_list-transl'>век живи, век учись;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>28.</span> Ups and downs — <span span className='v-s_list-transl'>взлёты и падения;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>29.</span> Do’s and don’ts  — <span span className='v-s_list-transl'>что можно и что нельзя;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>30.</span> Loud and clear  — <span span className='v-s_list-transl'>громко и чётко;</span></p>


            <p translate="no" className='v-s_list-title_binomials'>THE MOST COMMON BINOMIALS WITH "or"</p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>31.</span> More or less —  <span span className='v-s_list-transl'>более-менее;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>32.</span> All or nothing — <span span className='v-s_list-transl'>всё или ничего;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>33.</span> Take it or leave it — <span span className='v-s_list-transl'>принять или отказаться;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>34.</span> Sink or swim  — <span span className='v-s_list-transl'>пан или пропал;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>35.</span> Sooner or later — <span span className='v-s_list-transl'> раньше или позже;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>36.</span> Rain or shine — <span span className='v-s_list-transl'>при любых условиях;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>37.</span> Heads or tails — <span span className='v-s_list-transl'>орёл или решка;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>38.</span> Now or never — <span span className='v-s_list-transl'> сейчас или никогда;</span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>39.</span> Make or break — <span span className='v-s_list-transl'>создать или разрушить; </span></p>
            <p translate="no" className='v-s_list-item'><span className='v-s_list-number'>40.</span> Trick or treat  — <span span className='v-s_list-transl'>сладость или шалость.</span></p>
            

            
            
          </div>
    </div>
  )
  
}

export default BinomialsContainer
