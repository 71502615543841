import React from 'react'

import './ReadingStep3.css'
import ReadingLevelContainer from '../ReadingLevelContainer/ReadingLevelContainer'
import ReadingItem from '../ReadingItem/ReadingItem'

function ReadingStep3() {
  return (
    <ReadingLevelContainer>
      <ReadingItem
        title="Step 21"
        className="reading-item__image reading-item__image_21"
        rule1="1. Буквосочетание «ou» [aʊ]"
        rule2="2. Запомни слово «our» [ˈaʊə]"
        example1="&bull;&nbsp;a cloud &bull;&nbsp;loud &bull;&nbsp;proud"
        example2="&bull;&nbsp;a house &bull;&nbsp;a mouse"
        example3="&bull;&nbsp;round &bull;&nbsp;the ground"
        example6="&bull;&nbsp;It is a dark cloud."
        example5="&bull;&nbsp;Our bell is loud."
        example4="&bull;&nbsp;Our house is big."
        imgExample="It is our house."
      />

      <ReadingItem
        title="Step 22"
        className="reading-item__image reading-item__image_22"
        rule1="1. Буква «Uu» в открытом слоге [juː]"
        rule2="2. Запомни имя «Luke» [luːk]"
        example1="&bull;&nbsp;a tune &bull;&nbsp;cute &bull;&nbsp;tube"
        example2="&bull;&nbsp;a mule &bull;&nbsp;due &bull;&nbsp;June"
        example4="&bull;&nbsp;Luke likes the tune."
        example5="&bull;&nbsp;My cat is cute."
        example6="&bull;&nbsp;June is hot."
        imgExample="It is July."
      />
      <ReadingItem
        title="Step 23"
        className="reading-item__image reading-item__image_23"
        
        rule1="1. Буква «Gg» перед согласными и «a», «o», «u» [g]"
        rule2="2. Буква «Gg» перед «i», «e», «y»  [dʒ], но&nbsp;«get» [ɡet]"
        example1='&bull;&nbsp;to go &bull;&nbsp;a game &bull;&nbsp;green'
        example2='&bull;&nbsp;George &bull;&nbsp;a page &bull;&nbsp;energy'
        example4='&bull;&nbsp;Glen has a green bike.'
        example5='&bull;&nbsp;We like good games.'
        example6='&bull;&nbsp;Gabe has a good book.'
        imgExample="green energy"
       
      />
      <ReadingItem
        title="Step 24"
        className="reading-item__image reading-item__image_24"
        rule1="1. Буквосочетание «igh» [ai] "
        rule2="2. Сравни слова «light» и «lite» [ˈlaɪt]"
        example1="&bull;&nbsp;high &bull;&nbsp;to sigh &bull;&nbsp;bright"
        example3="&bull;&nbsp;light &bull;&nbsp;at night &bull;&nbsp;tight"
        example2="&bull;&nbsp;might &bull;&nbsp;to fight &bull;&nbsp;right"
        example4="&bull;&nbsp;The sun is bright."
        example5="&bull;&nbsp;You are right."
        example6="&bull;&nbsp;I can see stars at night."
        imgExample="The sunlight is bright."
      />

      <ReadingItem
        title="Step 25"
        className="reading-item__image reading-item__image_25"
        rule1="1. Буквa «Ee» в открытом слоге [i:]"
        rule2="2. Запомни вариации имени «Pete» и «Peter [ˈpiːtə]»"
        example3="&bull;&nbsp;Pete &bull;&nbsp;Steve &bull;&nbsp;Eva &bull;&nbsp;eve"
        example1="Сравни слова:"
        example2="mete — met."
        example4="&bull;&nbsp;He is Steve."
        example5="&bull;&nbsp;He is Pete."
        example6="&bull;&nbsp;He is Peter."
        imgExample="Steve and Eva"
      />

      <ReadingItem
        title="Step 26"
        className="reading-item__image reading-item__image_26"
        rule1="1. Буквосочетание «au» [ɔ:]"
        rule2="2. Запомни слово «aunt» [ɑːnt]"
        example1="&bull;&nbsp;pause &bull;&nbsp;August &bull;&nbsp;sause"
        example2="&bull;&nbsp;Paul &bull;&nbsp;Paulina &bull;&nbsp;a cause"
        example4="&bull;&nbsp;Paul is at home."
        example5="&bull;&nbsp;Pauline is our aunt."
        example6="&bull;&nbsp;It is August."
        imgExample="Paul is at home."
      />
       <ReadingItem 
        title="Step 27"
        className="reading-item__image reading-item__image_27"
        rule1="1. Буквосочетание «kn» [n] "
        rule2="2. Запомни слово «know» [nəʊ]"
        example3='&bull;&nbsp;a knee &bull;&nbsp;to knit &bull;&nbsp;a knight'
        example1='Сравни слова:'
        example2='a night — a knight'
        example4='&bull;&nbsp;I can knit.'
        example5='&bull;&nbsp;We see a knight.'
        example6='&bull;&nbsp;I know it.'
        imgExample="He is a knight."
        /> 

      <ReadingItem
        title="Step 28"
        className="reading-item__image reading-item__image_28"
        rule1="1. Буквосочетание «wa» 1 — [wɔ] / 2&nbsp;—&nbsp;[wɔ:] "
        rule2="2. Запомни слово «warm» [wɔːm]"
        example2="2. &bull;&nbsp;water &bull;&nbsp;warm &bull;&nbsp;a wall"
        example1='1. &bull;&nbsp;to want &bull;&nbsp;to wasp &bull;&nbsp;to wash'
        example4="&bull;&nbsp;We like warm water."
        example5="&bull;&nbsp;Wendy wants a cup of tea."
        example6="&bull;&nbsp;We want to see a wasp."
        imgExample="The water is warm."
      />

      <ReadingItem
        title="Step 29"
        className="reading-item__image  reading-item__image_29"
        rule1="1. Буквосочетание «ow» в большинстве глаголов [əʊ]"
        rule2="2. Запомни слова «yellow» [ˈjeləʊ] и&nbsp;«a&nbsp;bowl»&nbsp;[bəʊl]"
        example1="&bull;&nbsp;to snow &bull;&nbsp;to know"
        example2="&bull;&nbsp;to slow &bull;&nbsp;to blow"
        example3="&bull;&nbsp;to grow &bull;&nbsp;to bow"

        example4="&bull;&nbsp;I know it snows."
        example5="&bull;&nbsp;You grow yellow roses."
        example6="&bull;&nbsp;My bowl is yellow."
        imgExample="We grow yellow flowers."
      />

      <ReadingItem
        title="Step 30"
        className="reading-item__image reading-item__image_30"
        rule1="1. Буквосочетание «ow» в других словах [aʊ]"
        rule2="2. Запомни слово «Moscow»  [ˈmɒskəʊ]"
        example1="&bull;&nbsp;now &bull;&nbsp;down &bull;&nbsp;a gown"
        example2="&bull;&nbsp;a bow &bull;&nbsp;a cow &bull;&nbsp;a town"
        example3="&bull;&nbsp;brown &bull;&nbsp;a clown &bull;&nbsp;how"
        example4=" Сравни слова: "
        example5=" to bow — a bow"
        example6="&bull;&nbsp;Let us slow [sləʊ] down."
        imgExample="The cow has brown spots."
      />
    </ReadingLevelContainer>
  )
}

export default ReadingStep3
