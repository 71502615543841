import React from 'react'

import './LinkersContainer.css'
import LinkersSlide from '../LinkersSlide/LinkersSlide'

function LinkersContainer() {
  return (
    <div className="linkers-container">
      
      <div className='s-v__slides-container'>
        <LinkersSlide
          title="Express your opinion"
          text1="&#8727; In my opinion"
          text2="&#8727; To my mind"
          text3="&#8727;  It seems to me that"
          text4="&#8727; It strikes me that "
          text5="&#8727; I am inclined to believe"
          slideClassName="linkers-slide__image linkers-slide__image_1"
          example="In my opinion, it's the best art school in our neighbourhood."
        />
        <LinkersSlide
          title="Express cause"
          text1="&#8727; Because"
          text2="&#8727; Because of"
          text3="&#8727; Due to"
          text4="&#8727; Owing to"
          text5="&#8727; For this reason"
          slideClassName="linkers-slide__image linkers-slide__image_4"
          example="Charles was sometimes late for work bacause of traffic jams."
        />
        <LinkersSlide
          title="Express effect"
          text1="&#8727; Therefore"
          text2="&#8727; So"
          text3="&#8727; Thus"
          text4="&#8727; Consequently"
          text5="&#8727; As a result"
          slideClassName="linkers-slide__image linkers-slide__image_2"
          example="Linda left her folder on the desk. Thus, she had to return to fetch it."
        />
        <LinkersSlide
          title="Make contrasting points"
          text1="&#8727; Although"
          text2="&#8727; Though"
          text3="&#8727; However"
          text4="&#8727; In spite of"
          text5="&#8727; Despite"
          slideClassName="linkers-slide__image linkers-slide__image_3"
          example="In spite of the rainy weather, they went for a walk."
        />

        <LinkersSlide
          title="State other people's opinion"
          text1="&#8727; People claim that"
          text2="&#8727; It is populary believed that"
          text3="&#8727; Nowadays more and more people think that"
          text4="&#8727; People argue that"
          text5="&#8727; Contrary to popular belief"
          slideClassName="linkers-slide__image linkers-slide__image_5"
          example="It is populary believed that healthy food has lots of benefits."
        />
        <LinkersSlide
          title="Emphasise what you say"
          text1="&#8727; Clearly"
          text2="&#8727; Obviously"
          text3="&#8727; In particular"
          text4="&#8727; Needless to say"
          text5="&#8727; Of course"
          slideClassName="linkers-slide__image linkers-slide__image_6"
          example="Needless to say, team sports can develop discipline and endurance."
        />
        <LinkersSlide
          title="Make general statements"
          text1="&#8727; As a rule"
          text2="&#8727; Generally"
          text3="&#8727; In general"
          text4="&#8727; On the whole"
          text5="&#8727; As a general rule"
          slideClassName="linkers-slide__image linkers-slide__image_9"
          example="As a general rule, pupils start school at the age of seven or six."
        />
        <LinkersSlide
          title="Express reality"
          text1="&#8727; In fact"
          text2="&#8727; Actually"
          text3="&#8727; As a matter of fact"
          text4="&#8727; In practice"
          text5="&#8727; The fact of the matter is that"
          slideClassName="linkers-slide__image linkers-slide__image_10"
          example="In practice, people tend to obtain tickets online to save their time."
        />
        <LinkersSlide
          title="List points"
          text1="&#8727; Firstly"
          text2="&#8727; First of all"
          text3="&#8727; To start with"
          text4="&#8727; Secondly"
          text5="&#8727; Thirdly"
          slideClassName="linkers-slide__image linkers-slide__image_8"
          example="&nbsp;&nbsp;Firstly, they signed the contract as it was a win-win situation."
        />
        <LinkersSlide
          title="Conclude"
          text1="&#8727; All in all"
          text2="&#8727; On the whole"
          text3="&#8727; In conclusion"
          text4="&#8727; Taking everything into account"
          text5="&#8727; All things considered"
          slideClassName="linkers-slide__image linkers-slide__image_7"
          example="In conclusion, people must protect endangered species."
        />
      </div>
    </div>
  )
}

export default LinkersContainer
