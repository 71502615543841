import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType1 from '../../../Sections/GrammarIntroType1/GrammarIntroType1'
import LinkersContainer from '../../../EducationSections/LinkersContainer/LinkersContainer'

function LinkersPage() {
  return (
    <div className="page">
      <EducationalHeader />
      <SecondaryContent>
        <GrammarIntroType1
          title="LINKERS"
          text=" What are linkers? Linkers are joining words or phrases that connect paragraphs, sentences, words and phrases so that a logical relationship is established between thoughts.
        They can be used at the start, halfway or at the end of the sentences.
        Linkers serve different tasks in a sentence. 
        Here are some categories of linkers which can help you to highlight contrast, compare, define, exemplify and summarize ideas, as well as to construct concise paragraphs.
        "
        />
        <LinkersContainer />
      </SecondaryContent>
      <SecondaryFooter />
    </div>
  )
}

export default LinkersPage
