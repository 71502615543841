import React from 'react'

import './PreschoolReadingContainer.css';
import ReadingAccordion from '../PreschoolReading/ReadingAccordion/ReadingAccordion'
import ReadingStep1 from '../PreschoolReading/ReadingStep1/ReadingStep1'
import ReadingStep2 from '../PreschoolReading/ReadingStep2/ReadingStep2'
import ReadingStep3 from '../PreschoolReading/ReadingStep3/ReadingStep3'
import ReadingStep4 from '../PreschoolReading/ReadingStep4/ReadingStep4'
import ReadingStep5 from '../PreschoolReading/ReadingStep5/ReadingStep5'


function PreschoolReadingContainer(props) {
  return (
    <div className="preschool-reading-container">


     
     <ReadingAccordion title="Unit 1">
            <ReadingStep1 />
          </ReadingAccordion>
          <ReadingAccordion title="Unit 2">
            <ReadingStep2 />
          </ReadingAccordion>
          <ReadingAccordion title="Unit 3">
            <ReadingStep3 />
          </ReadingAccordion>
          <ReadingAccordion title="Unit 4">
            <ReadingStep4 />
          </ReadingAccordion>
          <ReadingAccordion title="Unit 5">
            <ReadingStep5 />
          </ReadingAccordion>
          <span class="reading-accordion__line-decor"></span>
    </div>
  )
  
}

export default PreschoolReadingContainer
