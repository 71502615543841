import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType1 from '../../../Sections/GrammarIntroType1/GrammarIntroType1'
import BinomialsContainer from '../../../EducationSections/BinomialsContainer/BinomialsContainer'


function BinomialsPage() {
  return (
    <div className='page'>
       <EducationalHeader />
       <SecondaryContent>
       <GrammarIntroType1 
       title="BINOMIAL EXPRESSIONS"
       text =" Binomial expressions (also known as 'binomials' or 'binomial pairs') are well-known English phrases
      that include a pair of words usually joined by 'and', 'or' or 'by'. The common types of binomial pairs include 
      synonyms (e.g. hustle and bustle), opposites (e.g. all or nothing),
      allitiration (e.g. safe and sound), repetition (e.g. bit by bit) and similar sounding words (e.g. meet and greet). 
        "
       />
        <BinomialsContainer />
       </SecondaryContent>
        <SecondaryFooter />
    </div>
  )
}

export default BinomialsPage