import React from 'react'

import './MainContent.css'
// import MainGroups from '../MainGroups/MainGroups'
import MainIntro from '../MainIntro/MainIntro'
import MainShareLinks from '../MainShareLinks/MainShareLinks'
import MainContacts from '../MainContacts/MainContacts'
import Advert from '../Advert/Advert'
import QA from '../QA/QA'
import MainTeacher from '../MainTeacher/MainTeacher'
import Services from '../../Sections/Services/Services'
import MainVideo from '../MainVideo/MainVideo'


function MainContent() {
  return (
    <div className='main-content'>
      
      <MainIntro />
      <Advert />
      {/* <MainGroups /> */}
      <MainVideo />
      <Services />
      
      <MainTeacher />
      <QA />
      <MainShareLinks />
      <MainContacts />
      
    </div>
  )
}

export default MainContent