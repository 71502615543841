import React from 'react'

import './SecondaryTitle.css'

function SecondaryTitle(props) {
  return (
    <h2 translate="no" className="secondary-title">
       {props.title}
      </h2>
  )
}

export default SecondaryTitle