import React from 'react'
import { NavLink } from 'react-router-dom'

function PublicPolicy() {
  return (
    <div className="section">
      <div className="public-doc section-container ">
        <NavLink className="doc__back-link" to="/">
          &#8592; На главную
        </NavLink>
        <h2 className="doc__title">Политика конфиденциальности</h2>

        {/* 1 */}
        <h3 className="doc__subtitle">1 Общие положения</h3>
        <p className="doc__item">
          1.1 Настоящая Политика конфиденциальности (далее “Политика”) является
          неотъемлемой частью{' '}
          <NavLink to="/public-offer" className="doc__inner-link">
            Публичной оферты
          </NavLink>
          .
        </p>
        <p className="doc__item" translate="no">
          1.2 В данной Политике указано, как Парфенюк Татьяна Викторовна (далее
          "я", "моего", "оператор"), www.english.moscow (далее “сайт”) и мои
          услуги обеспечивают соответствие требованиям Федерального закона №
          152-ФЗ «О персональных данных».
        </p>

        {/* 2 */}
        <h3 className="doc__subtitle">2 Сбор информации</h3>
        <p className="doc__item">Я могу запросить следующие виды информации:</p>
        <p className="doc__item doc__ident">
          • фамилия, имя, отчетсво, год рождения;
        </p>
        <p className="doc__item doc__ident">• номер телефона;</p>
        <p className="doc__item doc__ident">• адрес электронной почты. </p>

        {/* 3 */}
        <h3 className="doc__subtitle">3 Использование информации</h3>
        <p className="doc__item">Я использую данную информацию для: </p>
        <p className="doc__item doc__ident">• организации процесса обучения;</p>
        <p className="doc__item doc__ident">
          • информирования о прогрессе обучения;{' '}
        </p>
        <p className="doc__item doc__ident">
          • предоставления информации о других моих учебных программах.
        </p>
        {/* 4 */}
        <h3 className="doc__subtitle">4 Раскрытие информации</h3>
        <p className="doc__item">
          Ваша персональные данные не передаются третьим лицам, за исключением
          случаев, когда это необходимо для соблюдения требований
          законодательства.
        </p>

        {/* 5 */}
        <h3 className="doc__subtitle">
          5 Персональные данные, разрешенные субъектом персональных данных для
          распространения
        </h3>
        <p className="doc__item">
          5.1 По вашему желанию вы можете оставить отзыв на странице&nbsp;
          <NavLink translate="no" to="/feedback" className="doc__inner-link">
            www.english.moscow/feedback
          </NavLink>
          .
        </p>
        <p className="doc__item">
          5.2 Прошедшие модерацию отзывы располагаются на серверах виртуального
          хостинга Рег.ру по адресу: г. Москва, пл. Академика Курчатова, д. 1,
          строение 46;
        </p>
        <p className="doc__item" translate="no">
          5.3 В опубликованном на странице www.english.moscow/feedback отзыве,
          будут отображены:
        </p>
        <p className="doc__item doc__ident">• содержимое поля “Имя”;</p>
        <p className="doc__item doc__ident">• текст отзыва;</p>
        <p className="doc__item doc__ident">• оценка качества услуг.</p>
        <p className="doc__item">
          5.4 Указанный электронный адрес используется для обратной связи и не
          отображается на сайте.
        </p>

        {/* 6 */}
        <h3 className="doc__subtitle">
          6 Файлы cookie и аналогичные технологии
        </h3>
        <p className="doc__item">
          Я не использую файлы cookie или аналогичные технологии собственного
          производства для сбора информации о пользователях сайта.
        </p>
        <p className="doc__item">
          Однако мы используем Яндекс.Метрику и Яндекс.Директ, которая может
          использовать cookie.
        </p>
        <p className="doc__item">
          Подробнее о том, как Яндекс.Метрика и Яндекс.Директ обрабатывают
          данные, вы можете узнать в их политике конфиденциальности.
        </p>

        {/* 7 */}
        <h3 className="doc__subtitle">7 Ваши права</h3>
        <p className="doc__item">Вы имеете право:</p>
        <p className="doc__item doc__ident">
          • запросить доступ к своей личной информации;
        </p>
        <p className="doc__item doc__ident">
          • исправить или обновить свою личную информацию;
        </p>
        <p className="doc__item doc__ident">
          • требовать удаления своей личной информации.
        </p>
        <p className="doc__item">
          Для реализации этих прав, пожалуйста, свяжитесь с нами по контактным
          данным, указанным ниже.
        </p>

        {/* 8 */}
        <h3 className="doc__subtitle">
          8 Изменения в Политике конфиденциальности
        </h3>
        <p className="doc__item">
          Я могу периодически обновлять настоящую Политику конфиденциальности.{' '}
        </p>
        <p className="doc__item">
          Изменения будут опубликованы на этой странице с указанием даты
          последнего обновления.
        </p>

        {/* 9 */}
        <h3 className="doc__subtitle">
          9 Принятие Политики конфиденциальности
        </h3>
        <p className="doc__item">
          Используя сайт english.moscow и услуги, вы подтверждаете, что
          ознакомились и согласны с условиями настоящей Политики
          конфиденциальности.
        </p>

				{/* 10 */}
        <h3 className="doc__subtitle">10 Контактная информация</h3>
        <p className="doc__item">
          Если у вас есть вопросы или комментарии по поводу данной Политики
          конфиденциальности, пожалуйста, свяжитесь со мной:
        </p>
        <p className="doc__item" translate="no">
          Email: info@english.moscow
        </p>
        <p className="doc__item" translate="no">
          Телефон: +7 (926) 33-77-444
        </p>
        <p className="doc__item doc__date">
          Дата последнего обновления: 01.06.2024
        </p>
      </div>
    </div>
  )
}

export default PublicPolicy
