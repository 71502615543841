import React from 'react'

import './PresentPerfectContinuousTense.css'
import GrammarAccordion from '../../../GrammarAccordion/GrammarAccordion'
import TenseRule from '../../../GrammarRules/TenseRule/TenseRule'

function PresentPerfectContinuousTense() {
  return (
    <GrammarAccordion title="Present Perfect Continuous">
      <TenseRule
        statement1="I / you  /we / they + have&nbsp;been&nbsp;verb+ing "
        statement2="He / she / it  + has been verb+ing"
        statementEg1="I've been gardening. "
        statementEg2="He's been gardening for four hours."

        negative1="I / you / we / they + haven't&nbsp;been&nbsp;verb+ing "
        negative2="He / she / it  + hasn't been verb+ing"
        negativeEg1="I haven't been gardening."
        negativeEg2="He hasn't been gardening for&nbsp;a&nbsp;long time."

        question1=" have + I / you / we / they  + been&nbsp;verb+ing"
        question2=" has + he / she / it  + been verb+ing"
        questionEg1="Have you been gardening?"
        questionEg2="How long has he been gardening?"

        marker1="all morning/day/etc.;"
        marker3="since Monday;"
        marker4="since 4 p.m;"
        marker5="since (I was born);"
        marker2="for a long time;"
        marker7="recently;"
        marker8="lately;"
        marker9="still;"
        marker12="today."
        marker10="How long;"
        marker11="this week/term/etc.;"
        marker6="for (two weeks); "
      >
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          to put emphasis on the duration of an action which hasn't finished yet;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          actions which lasted for some time;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          to express irritation, anger or annoyance;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          verbs: <span className='tense-rule__span-word'>work</span>, <span className='tense-rule__span-word'>live</span>, <span className='tense-rule__span-word'>teach</span>, <span className='tense-rule__span-word'>feel (for emotions)</span> can be used with Present Perfect or Present Prerfect Continuous with no difference in meaning.
        </li>
        
      </TenseRule>
    </GrammarAccordion>
  )
}

export default PresentPerfectContinuousTense
