import React from 'react'
import { NavLink } from 'react-router-dom'

import './MainFooter.css'

function MainFooter() {
  return (
    <footer className="main-footer section">
      <div className="section-container t-b-paddings">
        <div className="main-footer__info">
        <p className='main-footer__info-text' translate='no'>You are always welcome!</p>
          <p className='main-footer__info-text'>+7 (926) 33 77 444</p>
          <p  translate='no' className='main-footer__info-text'>&copy; 2023-2024 Study&nbsp;with&nbsp;Tatiana&nbsp;Parfe</p>
        </div>

        <div className='main-footer__docs'>
        <div>
        <NavLink
            translate="no"
            to="/public-offer"
            className="public"
          >
            Публичная оферта (при групповой форме обучения)
          </NavLink>
          <NavLink
            translate="no"
            to="/privacy-policy"
            className="public"
          >
            Политика конфедициальности
          </NavLink>
        </div>
        <div className="main-footer__info main-footer__info_extra">
          <p translate='no' className='main-footer__info-extra'>All rights reserved</p>
          <p translate='no' className='main-footer__info-extra'>Images designed by studiogstock / Freepik</p>
        </div>
        </div>
      </div>
    </footer>
  )
}

export default MainFooter
