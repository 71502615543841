import React from 'react'
import { NavLink } from 'react-router-dom'

import './BackHeaderButton.css'

function BackHeaderButton() {
  return (
    <NavLink to="/" className="secondaryHeader__backButton">
    На главную
  </NavLink>
  )
}

export default BackHeaderButton