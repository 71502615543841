import React from 'react'
import './Services.css'
import '../../Sections/MainGroups/MainGroups.css'
import ServiceItem from '../../Elements/ServiceItem/ServiceItem'

function Services() {
  return (
    <section className="services" id="services">
      <div className='main-groups__box'>
      <h3 translate='no' className="main-groups__title">Занятия по направлениям на&nbsp;выбор</h3>
      <p className="main-groups__subtitle"> индивидуально или в группe (от 2 до 7 человек)</p>
      </div>
      <div className="services__container">
        <ServiceItem
          title="С учениками младшей школы"
          // price_1="45 мин. / 800 &#8381;"
          price_2="45 мин. / 800 &#8381;"
          imageClassname="item__image first"
          // span_1="очный урок — "
          span_2="индивидуально — "
          book1="'Welcome'"
          book2="'Academy stars'"
          book3="'Fairyland'"
          description="Набираем словарный запас, учимся воспринимать на слух,
           знакомимся с основами грамматики, прорабатываем видеозадания и
           немного играем."
        />

        <ServiceItem
          title="C учениками средней и&nbsp;старшeй школы"
          price_1="месяц / 4000 &#8381;"
          price_2="60 мин. / 800 &#8381;"
          imageClassname="item__image second"
          span_2="индивидуально — "
          span_1="в мини группе — "
          book1="'Click on'"
          book2="'Gateway to the World'"
          book3="'Think'"
          description="Расширяем словарный запас, развиваем разговорные навыки, грамматика среднего и высокого уровня сложности, чтение и  видеоупражнения."
           
        />

        <ServiceItem
          title="&nbsp;&nbsp;&nbsp;&nbsp;Подготовка к&nbsp;EГЭ,&nbsp;ОГЭ и&nbsp;олимпиадам"
          price_1="месяц / 4000 &#8381;"
          price_2="60 мин. / 1000 &#8381;"
          imageClassname="item__image third"
          span_1="в мини группе — "
          span_2="индивидуально — "
          book1="'Macmillan exam skills'"
          book3="'Speakout writing / reading skills'"
          book2="'Grammarway'"
          description="Прорабатываем типовые тесты, выполняем задания по аудированию,
          учимся писать сочинения и официальные письма, изучаем грамматику."
       />
        

        <ServiceItem
          title="Студентам (general&nbsp;/&nbsp;exams)"
          // price_1="60 мин. / 1000 &#8381;"
          price_2="60 мин. / 1000 &#8381;"
          imageClassname="item__image fourth"
          // span_1="очный урок — "
          span_2="online урок — "
          book1="'Mission'"
          book2="'Enterprise'"
          book3="'Headway'"
          description="Подготовка к разным видам экзаменов и тестов. Как вариант, возможно изучение узкоспециализированной лексики."
        />

        <ServiceItem
          title="Для работы / путешествий"
          price_2="60 мин. / 1000 &#8381;"
          price_1="60 мин. / 1400 &#8381;"
          imageClassname="item__image fifth"
          span_2="A1–B2 — "
          span_1="C1–C2 — "
          book1="'New Total English'"
          book2="'Speakout'"
          book3="'Straightforward'"
          description=" 'Живой' английский. Тренируем произношение. Моделируем ситуационные диалоги для заграничных поездок. Обогащаем словарный запас"
        />

        <ServiceItem
          title="Индивидуальные занятия"
          imageClassname="item__image sixth"
          book2="Подготовка к олимпиаде"
          book3="Помощь с домашним заданием"
          book1="Подготовка к КР"
          description="Программа занятий составляется согласно вашим требованиям и целям изучения языка."
        />
      </div>
    </section>
  )
}

export default Services
