import React from 'react'

import GrammarAccordion from '../../../GrammarAccordion/GrammarAccordion'
import TenseRule from '../../../GrammarRules/TenseRule/TenseRule'

function PastContinuousTense() {
  return (
    <GrammarAccordion title="Past Continuous">
    <TenseRule
        statement1="I / he / she / it + was + verb+ing"
        statement2="we / you / they  + were + verb+ing"
        statementEg1="I was working."
        statementEg2="They were working."

        negative1="I / he / she / it + wasn't + verb+ing"
        negative2="we / you / they  + weren't + verb+ing"
        negativeEg1="I wasn't working."
        negativeEg2="They weren't working."

        question1="was + I / he / she / it  + verb+ing"
        question2="were / you / they + verb+ing"
        questionEg1="What was he working on?"
        questionEg2="What were they working on?"

        marker1="while;"
        marker2="as;"
        marker3="when;"
        marker4="all morning / evening / etc.;"
        marker5="all day long;"
        marker6="at 5 o'clock;"
        marker7="from 5 to 7 p.m.;"
        marker8="in the morning / afternoon / etc.;"
        marker9="last night;"
        marker10="at that moment;"
        marker11="at that time;"
        marker12="whilst."
      >
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          for the action which was in progress at a stated time in the past;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          to describe the atmosphere and setting;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          in the introduction to the story before the main events;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          for several simultaneous past actions;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          for an action which was in progress when another action interrupted it.
        </li>
      </TenseRule>
      </GrammarAccordion>
  )
}

export default PastContinuousTense