import React from 'react'

import './KidsVocabularyContainer2.css'

import SliderTitle from '../../Elements/SliderTitle/SliderTitle'
import NamesRow from '../../EducationSections/VocabularyRows/NamesRow/NamesRow'
import FamilyAndFriendsRow from '../../EducationSections/VocabularyRows/FamilyAndFriendsRow/FamilyAndFriensRow'
import SeasonsRow from '../../EducationSections/VocabularyRows/SeasonsRow/SeasonsRow'
import JobsRow from '../../EducationSections/VocabularyRows/JobsRow/JobsRow'
import SubjectsRow from '../../EducationSections/VocabularyRows/SubjectsRows/SubjectsRow'
import SportsRow from '../../EducationSections/VocabularyRows/SportsRow/SportsRow'
import RoomsRow from '../../EducationSections/VocabularyRows/RoomsRow/RoomsRow'
import WeatherRow from '../../EducationSections/VocabularyRows/WeatherRow/WeatherRow'

function KidsVocabularyContainer2() {
  return (
    <div className="Kids-vocabulary-container">
      <SliderTitle title="NAMES" />
      <h3 translate="no" className="slider__subtitle">
      Hello! What is your name?
      </h3>
      <NamesRow />

      <SliderTitle title="FAMILY AND FRIENDS" />
      <h3 translate="no" className="slider__subtitle">
        Who is he? / Who is she?
      </h3>
      <FamilyAndFriendsRow />

      
      <SliderTitle title="SEASONS" />
        <h3 translate="no" className="slider__subtitle">
          What season is it?
        </h3>
        <SeasonsRow />

        <SliderTitle title="JOBS" />
      <h3 translate="no" className="slider__subtitle">
          What is his job? What is her job?
      </h3>
      <JobsRow />

      <SliderTitle title="SUBJECTS" />
            <h3 translate="no" className="slider__subtitle">
          What is your favourite subject?
        </h3>
        <SubjectsRow />

        <SliderTitle title="SPORTS" /> 
        <h3 translate="no" className="slider__subtitle">
          What sport do you go in for?
        </h3>
        <SportsRow />

        <SliderTitle title="ROOMS" />
        <h3 translate="no" className="slider__subtitle">
          Where do you cook? (play,&nbsp;work,&nbsp;etc.)
        </h3>
        <RoomsRow />

        <SliderTitle title="THE WEATHER" />
        <h3 translate="no" className="slider__subtitle">
          What is the weather like today?
        </h3>
        <WeatherRow />
    </div>
  )
}

export default KidsVocabularyContainer2
