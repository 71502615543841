import React from 'react'

// import 'swiper/css/bundle'
import './ColoursRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'
// const blueTrack = document.getElementById('blueID')
//
// const blue = require('./blue.mp3')

function ColoursRow() {

  /* for blue */
  const blue = require('./blue.mp3') 
  function bluePlay() {
    const blueTrack = document.getElementById('blueID')
    blueTrack.play()
  }

  /* for green */
  const green = require('./green.mp3')
  function greenPlay() {
    const greenTrack = document.getElementById('greenID')
    greenTrack.play()
  }

  /* for red */
  const red = require('./red.mp3')
  function redPlay() {
    const redTrack = document.getElementById('redID')
    redTrack.play()
  }

  /* for orange*/
  const orange = require('./orange.mp3')
  function orangePlay() {
    const orangeTrack = document.getElementById('orangeID')
    orangeTrack.play()
  }

  /* for white */
  const white = require('./white.mp3')
  function whitePlay() {
    const whiteTrack = document.getElementById('whiteID')
    whiteTrack.play()
  }

  /* for yellow */
  const yellow = require('./yellow.mp3')
  function yellowPlay() {
    const yellowTrack = document.getElementById('yellowID')
    yellowTrack.play()
  }

  /* for pink */
  const pink = require('./pink.mp3')
  function pinkPlay() {
    const pinkTrack = document.getElementById('pinkID')
    pinkTrack.play()
  }

  /* for brown */
  const brown = require('./brown.mp3')
  function brownPlay() {
    const brownTrack = document.getElementById('brownID')
    brownTrack.play()
  }

  /* for black */
  const black = require('./black.mp3')
  function blackPlay() {
    const blackTrack = document.getElementById('blackID')
    blackTrack.play()
  }

  /* for purple */
  const purple = require('./purple.mp3')
  function purplePlay() {
    const purpleTrack = document.getElementById('purpleID')
    purpleTrack.play()
  }

  return (
    <DataSlider>
      {/* for blue */}
      <div>
        <div className="kids-slide kids-slide__image_1" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              blue
            </p>
            <button className="kids-slide__sound-btn" onClick={bluePlay} />
          </div>
          <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for green */}
      <div>
        <div className="kids-slide kids-slide__image_2" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              green
            </p>
            <button className="kids-slide__sound-btn" onClick={greenPlay} />
          </div>
          <audio id="greenID">
            <source src={green} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for red */}
      <div>
        <div className="kids-slide kids-slide__image_3" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              red
            </p>
            <button className="kids-slide__sound-btn" onClick={redPlay} />
          </div>
          <audio id="redID">
            <source src={red} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for orange */}
      <div>
        <div className="kids-slide kids-slide__image_4" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              orange
            </p>
            <button className="kids-slide__sound-btn" onClick={orangePlay} />
          </div>
          <audio id="orangeID">
            <source src={orange} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for yellow */}
      <div>
        <div className="kids-slide kids-slide__image_5" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              yellow
            </p>
            <button className="kids-slide__sound-btn" onClick={yellowPlay} />
          </div>
          <audio id="yellowID">
            <source src={yellow} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for pink */}
      <div>
        <div className="kids-slide kids-slide__image_6" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              pink
            </p>
            <button className="kids-slide__sound-btn" onClick={pinkPlay} />
          </div>
          <audio id="pinkID">
            <source src={pink} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for brown */}
      <div>
        <div className="kids-slide kids-slide__image_7" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              brown
            </p>
            <button className="kids-slide__sound-btn" onClick={brownPlay} />
          </div>
          <audio id="brownID">
            <source src={brown} type="audio/mp3" />
          </audio>
        </div>
      </div>

      <div>
        <div className="kids-slide kids-slide__image_8" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              white
            </p>
            <button className="kids-slide__sound-btn" onClick={whitePlay} />
          </div>
          <audio id="whiteID">
            <source src={white} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for black */}
      <div>
        <div className="kids-slide kids-slide__image_9" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              black
            </p>
            <button className="kids-slide__sound-btn" onClick={blackPlay} />
          </div>
          <audio id="blackID">
            <source src={black} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for purple */}
      <div>
        <div className="kids-slide kids-slide__image_10" style={{ width: 280 }}>
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              purple
            </p>
            <button className="kids-slide__sound-btn" onClick={purplePlay} />
          </div>
          <audio id="purpleID">
            <source src={purple} type="audio/mp3" />
          </audio>
        </div>
      </div>
    </DataSlider>
  )
}
export default ColoursRow
