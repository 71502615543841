import React, { useState } from 'react'

import './IrregularVerbsContainer.css'
import IrregularVerbItem from '../IrregularVerbsItem/IrregularVerbsItem'

function IrregularVerbsContainer() {
  const [allVerbsShown, setAllVerbsShown] = useState(false)
  function showAllVerbs() {
    setAllVerbsShown(!allVerbsShown)
  }

  return (
    <div>
      <div className="irVerbs__golden-box">
        <div className="IrVerbs__checkbox-area">
          <input
            type="checkbox"
            onChange={showAllVerbs}
            className="irVerbs-checkbox__input"
            id="ir-verbs"
          />
          <label
            htmlFor="ir-verbs"
            className={
              allVerbsShown
                ? ' IrVerbs-checkbox__label IrVerbs-checkbox__label_checked'
                : 'IrVerbs-checkbox__label'
            }
          >
            <span
              className={
                allVerbsShown
                  ? 'IrVerbs-checkbox__circle_checked'
                  : 'irVerbs-checkbox__circle'
              }
            ></span>
          </label>
          <p className="IrVerbs__switch-text" translate="no">
            Uncover all the cards. / Показать все карточки.{' '}
          </p>
        </div>

        <p translate="no" className="irVerbs_instruction-line">
          Or you can uncover each one separately by touching them. /
          Или&nbsp;нажмите&nbsp;на каждую отдельно.
        </p>
      </div>
      <div className="IrVerbs__wrapper">
        <IrregularVerbItem
          verb1="be"
          verb2="was&nbsp;/&nbsp;were"
          verb3="been"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="bear"
          verb2="bore"
          verb3="born"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="beat"
          verb2="beat"
          verb3="beaten"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="become"
          verb2="became"
          verb3="become"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="begin"
          verb2="began"
          verb3="begun"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="bite"
          verb2="bit"
          verb3="bitten"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="blow"
          verb2="blew"
          verb3="blown"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="break"
          verb2="broke"
          verb3="broken"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="bring"
          verb2="brought"
          verb3="brought"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="build"
          verb2="built"
          verb3="built"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="burn"
          verb2="burnt / burned"
          verb3="burnt / burned"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="burst"
          verb2="burst"
          verb3="burst"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="buy"
          verb2="bought"
          verb3="bought"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="can"
          verb2="could"
          verb3="been able to"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="catch"
          verb2="caught"
          verb3="caught"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="choose"
          verb2="chose"
          verb3="chosen"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="come"
          verb2="came"
          verb3="come"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="cost"
          verb2="cost"
          verb3="cost"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="cut"
          verb2="cut"
          verb3="cut"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="deal"
          verb2="dealt"
          verb3="dealt"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="dig"
          verb2="dug"
          verb3="dug"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="do"
          verb2="did"
          verb3="done"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="dream"
          verb2="dreamt / dreamed"
          verb3="dreamt / dreamed"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="drink"
          verb2="drank"
          verb3="drunk"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="drive"
          verb2="drove"
          verb3="driven"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="eat"
          verb2="ate"
          verb3="eaten"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="fall"
          verb2="fell"
          verb3="fallen"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="feed"
          verb2="fed"
          verb3="fed"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="feel"
          verb2="felt"
          verb3="felt"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="fight"
          verb2="fought"
          verb3="fought"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="find"
          verb2="found"
          verb3="found"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="flee"
          verb2="fled"
          verb3="fled"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="fly"
          verb2="flew"
          verb3="flown"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="forbid"
          verb2="forbade"
          verb3="forbidden"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="forget"
          verb2="forgot"
          verb3="forgotten"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="forgive"
          verb2="forgave"
          verb3="forgiven"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="freeze"
          verb2="froze"
          verb3="frozen"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="get"
          verb2="got"
          verb3="got"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="give"
          verb2="gave"
          verb3="given"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="go"
          verb2="went"
          verb3="gone"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="grow"
          verb2="grew"
          verb3="grown"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="hang"
          verb2="hung / hanged"
          verb3="hung / hanged"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="have"
          verb2="had"
          verb3="had"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="hear"
          verb2="heard"
          verb3="heard"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="hide"
          verb2="hid"
          verb3="hidden"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="hit"
          verb2="hit"
          verb3="hit"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="hold"
          verb2="held"
          verb3="held"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="hurt"
          verb2="hurt"
          verb3="hurt"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="keep"
          verb2="kept"
          verb3="kept"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="know"
          verb2="knew"
          verb3="known"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="lay"
          verb2="laid"
          verb3="laid"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="lead"
          verb2="led"
          verb3="led"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="learn"
          verb2="learnt / learned"
          verb3="learnt / learned"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="leave"
          verb2="left"
          verb3="left"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="lend"
          verb2="lent"
          verb3="lent"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="let"
          verb2="let"
          verb3="let"
          allVerbsShown={allVerbsShown}
        />

        <IrregularVerbItem
          verb1="lie"
          verb2="lay"
          verb3="lain"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="light"
          verb2="lit"
          verb3="lit"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="lose"
          verb2="lost"
          verb3="lost"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="make"
          verb2="made"
          verb3="made"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="mean"
          verb2="meant"
          verb3="meant"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="meet"
          verb2="met"
          verb3="met"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="pay"
          verb2="paid"
          verb3="paid"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="prove"
          verb2="proved"
          verb3="proven"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="put"
          verb2="put"
          verb3="put"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="read"
          verb2="read"
          verb3="read"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="ride"
          verb2="rode"
          verb3="ridden"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="ring"
          verb2="rang"
          verb3="rung"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="rise"
          verb2="rose"
          verb3="risen"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="run"
          verb2="ran"
          verb3="run"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="say"
          verb2="said"
          verb3="said"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="see"
          verb2="saw"
          verb3="seen"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="seek"
          verb2="sought"
          verb3="siught"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sell"
          verb2="sold"
          verb3="sold"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="send"
          verb2="sent"
          verb3="sent"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="set"
          verb2="set"
          verb3="set"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sew"
          verb2="sewed"
          verb3="sewn / sewed"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="shake"
          verb2="shook"
          verb3="shaken"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="shine"
          verb2="shone"
          verb3="shone"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="shoot"
          verb2="shot"
          verb3="shot"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="show"
          verb2="showed"
          verb3="shown"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="shut"
          verb2="shut"
          verb3="shut"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sing"
          verb2="sang"
          verb3="sung"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sit"
          verb2="sat"
          verb3="sat"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sleep"
          verb2="slept"
          verb3="slept"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="smell"
          verb2="smelt / smelled"
          verb3="smelt / smelled"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="speak"
          verb2="spoke"
          verb3="spoken"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="spell"
          verb2="spelt / spelled"
          verb3="spelt / spelled"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="spend"
          verb2="spent"
          verb3="spent"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="split"
          verb2="split"
          verb3="split"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="spread"
          verb2="spread"
          verb3="spread"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="spring"
          verb2="sprang"
          verb3="sprung"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="stand"
          verb2="stood"
          verb3="stood"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="steal"
          verb2="stole"
          verb3="stolen"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="stick"
          verb2="stuck"
          verb3="stuck"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sting"
          verb2="stung"
          verb3="stung"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="stink"
          verb2="stank"
          verb3="stunk"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="strike"
          verb2="struck"
          verb3="struck"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="swear"
          verb2="swore"
          verb3="sworn"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="sweep"
          verb2="swept"
          verb3="swept"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="swim"
          verb2="swam"
          verb3="swum"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="take"
          verb2="took"
          verb3="taken"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="teach"
          verb2="taught"
          verb3="taught"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="tear"
          verb2="tore"
          verb3="torn"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="tell"
          verb2="told"
          verb3="told"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="think"
          verb2="thought"
          verb3="thought"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="throw"
          verb2="threw"
          verb3="thrown"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="understand"
          verb2="understood"
          verb3="understood"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="wake"
          verb2="woke"
          verb3="woken"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="wear"
          verb2="wore"
          verb3="worn"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="win"
          verb2="won"
          verb3="won"
          allVerbsShown={allVerbsShown}
        />
        <IrregularVerbItem
          verb1="write"
          verb2="wrote"
          verb3="written"
          allVerbsShown={allVerbsShown}
        />
      </div>
    </div>
  )
}

export default IrregularVerbsContainer
