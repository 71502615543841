import React from 'react'

import './RoomsRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function RoomsRow() {
  /* for blue */
  //   const blueTrack = document.getElementById('blueID')
  //   const blue = require('./blue.mp3')
  //   function bluePlay() {
  //     blueTrack.play()
  //   }
  //   /* for green */
  //   const greenTrack = document.getElementById('greenID')
  //   const green = require('./green.mp3')
  //   function greenPlay() {
  //     greenTrack.play()
  //   }

  //   /* for red */
  //   const redTrack = document.getElementById('redID')
  //   const red = require('./red.mp3')
  //   function redPlay() {
  //     redTrack.play()
  //   }
  //   /* for orange*/
  //   const orangeTrack = document.getElementById('orangeID')
  //   const orange = require('./orange.mp3')
  //   function orangePlay() {
  //     orangeTrack.play()
  //   }

  //   /* for white */
  //   const whiteTrack = document.getElementById('whiteID')
  //   const white = require('./white.mp3')
  //   function whitePlay() {
  //     whiteTrack.play()
  //   }

  //   /* for yellow */
  //   const yellowTrack = document.getElementById('yellowID')
  //   const yellow = require('./yellow.mp3')
  //   function yellowPlay() {
  //     yellowTrack.play()
  //   }

  //   /* for pink */
  //   const pinkTrack = document.getElementById('pinkID')
  //   const pink = require('./pink.mp3')
  //   function pinkPlay() {
  //     pinkTrack.play()
  //   }

  //   /* for brown */
  //   const brownTrack = document.getElementById('brownID')
  //   const brown = require('./brown.mp3')
  //   function brownPlay() {
  //     brownTrack.play()
  //   }

  //   /* for black */
  //   const blackTrack = document.getElementById('blackID')
  //   const black = require('./black.mp3')
  //   function blackPlay() {
  //     blackTrack.play()
  //   }

  //   /* for purple */
  //   const purpleTrack = document.getElementById('purpleID')
  //   const purple = require('./purple.mp3')
  //   function purplePlay() {
  //     purpleTrack.play()
  //   }

  return (
    <DataSlider>
      {/* for kitchen */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_kitchen">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the kitchen
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Where do you cook? We&nbsp;cook&nbsp;in&nbsp;the kitchen.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for dining room */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_dining-room">
            <div className="primary-school__short-info">
              <p  translate="no" className="primary-school-slide__text">
                the dining room
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you eat? We&nbsp;eat&nbsp;in&nbsp;the&nbsp;dining room.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for children's room */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_child-room">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the children's room
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you play? We&nbsp;play&nbsp;in&nbsp;the&nbsp;children's room.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for nursery */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_nursery">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the nursery
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where does your baby sister sleep? She&nbsp;sleeps&nbsp;in&nbsp;the&nbsp;nursery.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for bedroom */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_bedroom">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the bedroom
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you sleep? We&nbsp;sleep&nbsp;in&nbsp;the&nbsp;bedroom.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for bathdroom */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_bathroom">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the bathroom
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you wash up? We&nbsp;wash&nbsp;up&nbsp;in&nbsp;the&nbsp;bathroom.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for store room */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_store-room">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the store room
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you store your things? We&nbsp;store them&nbsp;in&nbsp;the&nbsp;store room.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for living room */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_living-room">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the living room
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you watch TV? We&nbsp;watch TV&nbsp;in&nbsp;the&nbsp;living room.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

       {/* for study */}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_study">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the study
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you work? We&nbsp;work&nbsp;in&nbsp;the&nbsp;study.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for balcony */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_balcony">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                the balcony
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            Where do you keep plants? We&nbsp;keep them&nbsp;on&nbsp;the&nbsp;balcony.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>
    </DataSlider>
  )
}
export default RoomsRow
