import React, { useState } from 'react'

import './TestSection.css'
import TestItem from '../../Elements/TestItem/TestItem'

function TestSection() {
  const [contentShown1, setContentShown1] = useState(false)
  const [contentShown2, setContentShown2] = useState(false)
  const [contentShown3, setContentShown3] = useState(false)
  const [contentShown4, setContentShown4] = useState(false)

  function handleShowContent1() {
    setContentShown1(!contentShown1)
  }
  function handleShowContent2() {
    setContentShown2(!contentShown2)
  }
  function handleShowContent3() {
    setContentShown3(!contentShown3)
  }
  function handleShowContent4() {
    setContentShown4(!contentShown4)
  }

  return (
    <section className="tests section">
      <div className="section-container t-b-paddings">
        <div className="test-popup__level">
          <button
            translate="no"
            onClick={handleShowContent1}
            className="tests__button"
          >
            <span className={contentShown1 ? ' draft-arrow up' : 'draft-arrow'} />Level 1
          </button>
          <div className={contentShown1 ? 'tests__q-area' : 'tests__q-area tests__q-area-hidden'}>
            <TestItem
              question="1) He ... an artist. I like his paintings."
              option1="a) is"
              option2="b) are"
              option3="c) do"
              option4="d) am"
            />
            <TestItem
              question="2) — Are you from Barcelona?  &#160;&#160;&#160;&#160;—&#160;No, I..."
              option1="a) isn't"
              option2="b) amn't"
              option3="c) 'm not"
              option4="d) aren't"
            />

            <TestItem
              question="3) I ... my homework every evening."
              option1="a) does"
              option2="b) do"
              option3="c) doing"
              option4="d) am doing"
            />

            <TestItem
              question="4) We often play table tennis ... Sundays."
              option1="a) in"
              option2="b) at"
              option3="c) the"
              option4="d) on"
            />

            <TestItem
              question="5) There ...  any sunflowers in the garden."
              option1="a) aren't"
              option2="b) are"
              option3="c) isn't"
              option4="d) is"
            />

            <TestItem
              question="6) Peter ... got two brothers and one sister."
              option1="a) have"
              option2="b) haves"
              option3="c) has"
              option4="d) does"
            />

            <TestItem
              question="7) Mr. Brown works ... engineer for a big company in Moscow."
              option1="a) — "
              option2="b) a"
              option3="c) an"
              option4="d) as an"
            />

            <TestItem
              question="8) Be quiet! The baby ... at the moment."
              option1="a) sleeping "
              option2="b) sleeps"
              option3="c) sleep"
              option4="d) is sleeping"
            />

            <TestItem
              question="9) There ... a lot of old buildings in this street when I was a child."
              option1="a) was"
              option2="b) were"
              option3="c) are"
              option4="d) did"
            />

            <TestItem
              question="10) That is Michael Brown. He is a friend of ... ."
              option1="a) me"
              option2="b) my"
              option3="c) mine"
              option4="d) I"
            />
          </div>
        </div>

        <div className="test-popup__level">
          <button
            translate="no"
            onClick={handleShowContent2}
            className="tests__button"
          >
            <span className={contentShown2 ? ' draft-arrow up' : 'draft-arrow'}/>Level 2
          </button>
          <div className={contentShown2 ? 'tests__q-area' : 'tests__q-area tests__q-area-hidden'}>
            <TestItem
              question="11) ... Amazon is ... longest river in ... world."
              option1="a) an, the, the"
              option2="b) the, the, the"
              option3="c) the,  — , the"
              option4="d)  — , the, the"
            />

            <TestItem
              question="12) Mary's published eight books and she is working on her ... one."
              option1="a) ninth"
              option2="b) nineth"
              option3="c) nineteenth"
              option4="d) nineteen"
            />

            <TestItem
              question="13) Julia won the game but her cousin ... ."
              option1="a) doesn't"
              option2="b) wasn't"
              option3="c) hasn't"
              option4="d) didn't"
            />

            <TestItem
              question="14) We were playing domino ... our aunt came."
              option1="a) when"
              option2="b) as"
              option3="c) , while"
              option4="d) , when"
            />

            <TestItem
              question="15) I ... my project yesterday. Mrs Smith .... it tomorrow."
              option1="a) finished, is going to check"
              option2="b) has finished, is going to check"
              option3="c) had finished, will check"
              option4="d) will finish, checked"
            />

            <TestItem
              question="16) Tom is ... boy in our baseball team."
              option1="a) tallest"
              option2="b) the tallest"
              option3="c) most tall"
              option4="d) the most tall"
            />

            <TestItem
              question="17) The students were studying History in the library, .... ?"
              option1="a) didn't they"
              option2="b) weren't we"
              option3="c) weren't they"
              option4="d) they were"
            />

            <TestItem
              question="18) I like vegetables. I eat a lot of fish ... ."
              option1="a) well as "
              option2="b) as well"
              option3="c) as well as"
              option4="d) as good"
            />

            <TestItem
              question="19) Sarah is late. She hasn't arrived home ... ."
              option1="a) recently"
              option2="b) two hours ago"
              option3="c) soon"
              option4="d) yet"
            />

            <TestItem
              question="20) We will go for a walk if the weather ... good.  "
              option1="a) be"
              option2="b) will be"
              option3="c) is"
              option4="d) is being"
            />
          </div>
        </div>

        <div className="test-popup__level">
          <button
            translate="no"
            onClick={handleShowContent3}
            className="tests__button"
          >
            <span className={contentShown3 ? ' draft-arrow up' : 'draft-arrow'} />
            Level 3
          </button>
          <div className={contentShown3 ? 'tests__q-area' : 'tests__q-area tests__q-area-hidden'}>
            <TestItem
              question="21) I used ... in the park a lot when I was a student."
              option1="a) to jog"
              option2="b) jog"
              option3="c) jogging"
              option4="d) jogged"
            />

            <TestItem
              question="22) The Greens live in a ... house in the countryside."
              option1="a) cozy wooden big"
              option2="b) wooden cozy big"
              option3="c) big cozy wooden"
              option4="d) cozy big wooden"
            />

            <TestItem
              question="23) Stephen ..... for nine months before he passed the exams."
              option1="a) has been studying"
              option2="b) had been studying"
              option3="c) was studying"
              option4="d) studied"
            />

            <TestItem
              question="24) ..... the question was tricky, I managed to answer it."
              option1="a) In spite of"
              option2="b) Although"
              option3="c) However"
              option4="d) Despite"
            />

            <TestItem
              question="25) I am looking ... my watch. Have you seen ... ?"
              option1="a) for, it"
              option2="b) to, it"
              option3="c) forward, them"
              option4="d) after, them"
            />

            <TestItem
              question="26) If I ... you, I wouldn't eat any junk food. "
              option1="a) am"
              option2="b) was"
              option3="c) were"
              option4="d) had been"
            />

            <TestItem
              question="27) You ... read this article. It's really interesting."
              option1="a) ought"
              option2="b) should"
              option3="c) have"
              option4="d) should to"
            />

            <TestItem
              question="28) I ... getting up at six o'clock. I'm an early bird."
              option1="a) used to"
              option2="b) am used"
              option3="c) am used to"
              option4="d) use to"
            />

            <TestItem
              question="29) You look .... in this suit and it matches your shoes. "
              option1="a) beautifully"
              option2="b) beautifuly"
              option3="c) beautiful"
              option4="d) beautifuler"
            />

            <TestItem
              question="30) My bedroom ... three weeks ago. "
              option1="a) was been wallpapered"
              option2="b) was wallpapered"
              option3="c) did wallpapared"
              option4="d) was being wallpapered"
            />
          </div>
        </div>

        <div className="test-popup__level">
          <button
            translate="no"
            onClick={handleShowContent4}
            className="tests__button"
          >
            <span className={contentShown4 ? ' draft-arrow up' : 'draft-arrow'} />
            Level 4
          </button>
          <div className={contentShown4 ? 'tests__q-area' : 'tests__q-area tests__q-area-hidden'}>
            <TestItem
              question="31) She said that she ... in 20 minutes. "
              option1="a) will come back"
              option2="b) would come back"
              option3="c) is coming back"
              option4="d) comes back"
            />

            <TestItem
              question="32) I would rather you ... on Wednesday."
              option1="a) come"
              option2="b) came"
              option3="c) to come"
              option4="d) have come"
            />

            <TestItem
              question="33) Ted .... that. It was very impolite."
              option1="a) shouldn't have said"
              option2="b) didn't have to say"
              option3="c) mustn't said"
              option4="d) shouldn't say"
            />

            <TestItem
              question="34) ... the door, I left home and rushed to the airport."
              option1="a) Locking"
              option2="b) Having locked"
              option3="c) After locked"
              option4="d) Having locking"
            />

            <TestItem
              question="35) By the time we have arrived, the roof ... ?"
              option1="a) will have repaired"
              option2="b) will have been repaired"
              option3="c) will has been repaired"
              option4="d) will has repaired"
            />

            <TestItem
              question="36) The letter has been written ... pen. "
              option1="a) by"
              option2="b) with"
              option3="c) in"
              option4="d) — "
            />

            <TestItem
              question="37) He has had to ... for the film role. "
              option1="a) let his hair cut "
              option2="b) have his hair cut "
              option3="c) be cutting his hair "
              option4="d) been cut his hair"
            />

            <TestItem
              question="38) He asked her whether ... London.  "
              option1="a) she liked "
              option2="b) she like "
              option3="c) has she liked "
              option4="d) did she like "
            />

            <TestItem
              question="39) Hardly .... my project when the phone rang. (Inversion)"
              option1="a) had I finished"
              option2="b) I finished"
              option3="c) I did finish"
              option4="d) did I finished"
            />

            <TestItem
              question="40) He ... like his new house. (Emphasis)"
              option1="a) do"
              option2="b) did"
              option3="c) does"
              option4="d) is"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestSection
