import React from 'react'

import './FamilyAndFriendsRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function FamilyAndFriendsRow() {
  /* for blue */
  //   const blueTrack = document.getElementById('blueID')
  //   const blue = require('./blue.mp3')
  //   function bluePlay() {
  //     blueTrack.play()
  //   }
  //   /* for green */
  //   const greenTrack = document.getElementById('greenID')
  //   const green = require('./green.mp3')
  //   function greenPlay() {
  //     greenTrack.play()
  //   }

  //   /* for red */
  //   const redTrack = document.getElementById('redID')
  //   const red = require('./red.mp3')
  //   function redPlay() {
  //     redTrack.play()
  //   }
  //   /* for orange*/
  //   const orangeTrack = document.getElementById('orangeID')
  //   const orange = require('./orange.mp3')
  //   function orangePlay() {
  //     orangeTrack.play()
  //   }

  //   /* for white */
  //   const whiteTrack = document.getElementById('whiteID')
  //   const white = require('./white.mp3')
  //   function whitePlay() {
  //     whiteTrack.play()
  //   }

  //   /* for yellow */
  //   const yellowTrack = document.getElementById('yellowID')
  //   const yellow = require('./yellow.mp3')
  //   function yellowPlay() {
  //     yellowTrack.play()
  //   }

  //   /* for pink */
  //   const pinkTrack = document.getElementById('pinkID')
  //   const pink = require('./pink.mp3')
  //   function pinkPlay() {
  //     pinkTrack.play()
  //   }

  //   /* for brown */
  //   const brownTrack = document.getElementById('brownID')
  //   const brown = require('./brown.mp3')
  //   function brownPlay() {
  //     brownTrack.play()
  //   }

  //   /* for black */
  //   const blackTrack = document.getElementById('blackID')
  //   const black = require('./black.mp3')
  //   function blackPlay() {
  //     blackTrack.play()
  //   }

  //   /* for purple */
  //   const purpleTrack = document.getElementById('purpleID')
  //   const purple = require('./purple.mp3')
  //   function purplePlay() {
  //     purpleTrack.play()
  //   }

  return (
    <DataSlider>
      {/* for father */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_father">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my father
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is he? He&nbsp;is&nbsp;my&nbsp;father.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for mother */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_mother">
            <div className="primary-school__short-info">
              <p  translate="no" className="primary-school-slide__text" translate="no">
                my mother
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is she? He&nbsp;is&nbsp;my&nbsp;mother.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for brother */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_brother">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my brother
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is he? He&nbsp;is&nbsp;my&nbsp;brother.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for sister */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_sister">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my sister
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is she? She&nbsp;is&nbsp;my&nbsp;sister.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for grandfather */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_grandfather">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my grandfather
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is he? He&nbsp;is&nbsp;my&nbsp;grandfather.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for grandmother */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_grandmother">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my grandmother
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is she&nbsp;? She&nbsp;is&nbsp;my&nbsp;grandmother.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for friend */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_friend">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my best friend
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is he? He&nbsp;is&nbsp;my&nbsp;best&nbsp;friend.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

       {/* for schoolmate */}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_classmate">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                my classmate
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              Who is she? She&nbsp;is&nbsp;my&nbsp;schoolmate.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>
    </DataSlider>
  )
}
export default FamilyAndFriendsRow
