import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType2 from '../../../Sections/GrammarIntroType2/GrammarIntroType2'
import PreschoolReadingContainer from '../../../EducationSections/PreschoolReadingContainer/PreschoolReadingContainer'

function PreschoolReadingPage() {
  return (
    <div className='page'>
    <EducationalHeader />
    <SecondaryContent>
     <GrammarIntroType2 
     title ="Тренажёр по чтению"
     />
     <PreschoolReadingContainer />
    </SecondaryContent>
     <SecondaryFooter />
 </div>
  )
}

export default PreschoolReadingPage