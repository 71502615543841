import React from 'react'

import './EducationalHeader.css'
import ToMenuButton from '../../Elements/ToMenuButton/ToMenuButton'
import HeaderLogoArea from '../../Elements/HeaderLogoArea/HeaderLogoArea'

function EducationalHeader() {
  return (
    <div className="secondary-header section">
      <div className="section-container secondary-header__container"> 
      <HeaderLogoArea />
      <ToMenuButton />
      </div>
    </div>
  )
}

export default EducationalHeader