import React from 'react'

import './MainContacts.css'

function MainContacts() {
  return (
    <section className="main-contacts section" id="contacts">
      <div className="section-container t-b-paddings">
      
        <h4 className="main-contacts__title">Позвонить или написать:</h4>
        <div className="main-contacts__items">
          <a
            href="mailto:info@english.moscow"
            target="_blank"
            rel="noreferrer"
            className="main-contacts__item main-contacts__mail"
          >
          </a>

          <a
            href="https://wa.me/79263377444"
            target="_blank"
            rel="noreferrer"
            className="main-contacts__item main-contacts__whatsapp"
          >
          </a>

          <a
            href="https://t.me/TatianaParfe"
            target="_blank"
            rel="noreferrer"
            className="main-contacts__item main-contacts__telegram"
          >
          </a>
        </div>
        <p className="main-contacts__text main-contacts__text_below">Начнём наши занятия в&nbsp;ближайшее время!</p>
      </div>
    </section>
  )
}

export default MainContacts
