import React from 'react'

import './TestItem.css'

function TestItem(props) {
  return (
    <div className="question-card">
        <p className="question-card_question" translate="no">{props.question}</p>
        <div className="question-card_options" >
            <p className="question-card_option" translate="no">{props.option1}</p>
            <p className="question-card_option" translate="no">{props.option2}</p>
            <p className="question-card_option" translate="no">{props.option3}</p>
            <p className="question-card_option" translate="no">{props.option4}</p>
        </div>
    </div>
  )
}

export default TestItem