import React, { useState }  from 'react'

import './HomePage.css'
import MainHeader from '../../Sections/MainHeader/MainHeader'
import MainContent from '../../Sections/MainContent/MainContent'
import MainFooter from '../../Sections/MainFooter/MainFooter'
import CardsMenuPopup from '../../Sections/CardsMenuPopup/CardsMenuPopup'

function HomePageMenuOpen() {
  const [menuOpen, setMenuOpen] = useState(true)

  function menuOpenHandler() {
    setMenuOpen(true)
  }

  function closeMenuHandle(){
    setMenuOpen(false)
  }

  return (
    <div className={menuOpen ?' shortpage' : 'page'}>
      <MainHeader menuOpenHandler={menuOpenHandler}/>
      <MainContent />
      <MainFooter />
      <CardsMenuPopup 
            closeMenuHandle={closeMenuHandle}
            className={ menuOpen ? "cards-menu-popup" : "cards-menu-popup cards-menu-popup-hidden"}/>
    </div>
  )
}

export default HomePageMenuOpen