import React from 'react'
import './KidsSlide.css'

function KidsSlide(props) {
  const track = document.getElementById('audioID')
  // const sound = require(`./${props.la}.mp3`)
  const sound = require(`./${props.la}.mp3`)
 
  function wordPlay() {
    track.play()
  }
  return (
    <div className={props.slideClassName}>
      <div className="kids-slide__card-info">
        <p className="kids-slide__text" translate="no">
          {props.text}
        </p>
        <button className={props.buttonClassName} onClick={wordPlay} />

      </div>
      <audio id="audioID">
        <source src={sound} type="audio/mp3" />
      </audio>
    </div>
  )
}

export default KidsSlide
