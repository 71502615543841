import React from 'react'

import './GrammarIntroType1.css'

function GrammarIntroType1(props) {
  return (
    <section className='grammar-intro-type-1 section'>
      <div className='section-container t-b-paddings' >
        <h2 className='grammar-intro-type-1__title' translate='no'>{props.title}</h2>
        <p className='grammar-intro-type-1__text' translate='no'>{props.text}</p>
      </div>
    </section>
  )
}

export default GrammarIntroType1