import React from 'react'

import './FeedbackMessage.css'

function FeedbackMessage(props) {
  return (
    <div className="fb-item">
      <div className="fb-item__decor fb-item__decor_first">❝</div>
      <div className="fb-item__content">
        <p translate='no' className="fb-item__text">{props.text}</p>
        <p className="feedback-item__starts">
              &#9733; &#9733; &#9733; &#9733; &#9733;
            </p>
        <div className="fb-item__author">
          <span className="fb-item__hand" />
          <div className={props.classAuthor} />
        </div>
      </div>
      <div className="fb-item__decor fb-item__decor_second">❞</div> 
    </div>
  )
}

export default FeedbackMessage
