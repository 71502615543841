import React from 'react'
import './IrregularVerbsItem.css';

function IrregularVerbItem(props) {
  return (
    <div className='irVerb-item'>
      <div>
        <p className='irVerb-first'translate="no">{props.verb1}</p>
      </div>
      <div className={props.allVerbsShown ? 'irVerb__not-first_shown' : 'irVerb__not-first'}>
        <p className='irVerbs-slide__list-item' translate="no" >{props.verb2}</p>
      </div> 
      <div className={props.allVerbsShown ? 'irVerb__not-first_shown' : 'irVerb__not-first'}>
        <p className='irVerbs-slide__list-item' translate="no" >{props.verb3}</p>
      </div>
    </div>
  )
}

export default IrregularVerbItem