import React from 'react'

import './GrammarIntroType2.css'

function GrammarIntroType2(props) {
  return (
    <section className='grammar-intro-type-2 section'>
      <div className='section-container t-b-paddings' >
        <h2 className='grammar-intro-type-2__title' translate='no'>{props.title}</h2>
        <p className='grammar-intro-type-2__text' translate='no'>{props.text}</p>
        <div className='grammar-intro-type-2__deco'/>
      </div>
    </section>
  )
}

export default GrammarIntroType2