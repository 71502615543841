import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType1 from '../../../Sections/GrammarIntroType1/GrammarIntroType1'
import ProverbsAndQuotesContainer from '../../../EducationSections/ProverbsAndQuotesContainer/ProverbsForEssays'


function ProverbsAndQuotesPage() {
  return (
    <div className='page'>
       <EducationalHeader />
       <SecondaryContent>
       <GrammarIntroType1 
       title="PROVERBS AND QUOTES &nbsp;FOR&nbsp;ESSAYS&nbsp;"
       text ="Proverbs are memorable, brief and often well-known statements that offer wisdom. Proverbs and quotes are widely used throughout the essays in order to make a point or share valuable advice.
        They are commonly mentioned in conversations as well. Some of them are famous around the world, other ones are tied to specific cultures or places. Here are some proverbs and quotes that can be used in compositions in order to highlight your idea and make it more meaningful. 
        "
       />
        <ProverbsAndQuotesContainer />
       </SecondaryContent>
        <SecondaryFooter />
    </div>
  )
}

export default ProverbsAndQuotesPage