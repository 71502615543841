import React from 'react'

import './ReadingStep4.css'
import ReadingLevelContainer from '../ReadingLevelContainer/ReadingLevelContainer'
import ReadingItem from '../ReadingItem/ReadingItem'

function ReadingStep4() {
  return (
    <ReadingLevelContainer>
      <ReadingItem 
        title="Step 31"
        className="reading-item__image reading-item__image_31"
        rule1="1. Буквосочетние «er» и «or»  в безударном слоге [ə]"
        rule2="2. Запомни имя «Oliver» [ɒlɪvə]"
        example1='&bull;&nbsp;a mixer &bull;&nbsp;a reader'
        example2='&bull;&nbsp;a doctor &bull;&nbsp;a factor'
        example4='&bull;&nbsp;Oliver is a doctor'
        example5='&bull;&nbsp;We have a computer.'
        example6='&bull;&nbsp;I have a mixer.'
        imgExample="Oliver has a computer."
        />

        <ReadingItem
        title="Step 32"
        className="reading-item__image reading-item__image_32"
        rule1="1. Буквосочетание «th» между гласными [ð]"
        rule2="2. Гласная «o» перед th [ʌ]"
        example1='&bull;&nbsp;my mother &bull;&nbsp;my brother ' 
        example2='&bull;&nbsp;my father &bull;&nbsp;together'
        example4='&bull;&nbsp;He is my father.'
        example5='&bull;&nbsp;She is my mother.'
        example6='&bull;&nbsp;He is my brother.'
        imgExample="We are together."
         />
        
        <ReadingItem 
        title="Step 33"
        className="reading-item__image reading-item__image_33"
        rule1="1. Буквосочетание «th» в приведенных ниже словах [ð]"
        rule2="2. Запомни слово «their» [ðeə]"
        example1='&bull;&nbsp;they &bull;&nbsp;them &bull;&nbsp;their &bull;&nbsp;theirs'
        example2='&bull;&nbsp;this &bull;&nbsp;that &bull;&nbsp;these &bull;&nbsp;those'
        example3='&bull;&nbsp;with &bull;&nbsp;the &bull;&nbsp;there'
        example4='&bull;&nbsp;This is their car.'
        example5='&bull;&nbsp;That is their house.'
        example6='&bull;&nbsp;They are packing their bags.'
        imgExample="They are my friends."
        
        />
        <ReadingItem 
        title="Step 34"
        className="reading-item__image reading-item__image_34"
        rule1="1. Буквосочетание «th» в  других словах [θ]"
        rule2="2. Запомни имя «Martha» [mɑːθə] и слово «live» [liv]"
        example1='&bull;&nbsp;the south &bull;&nbsp;thin &bull;&nbsp;thick'
        example2='&bull;&nbsp;a path &bull;&nbsp;maths &bull;&nbsp;three'
        example4='&bull;&nbsp;Elizabeth likes Maths.'
        example5='&bull;&nbsp;Martha lives in the south.'
        example6='&bull;&nbsp;My kith live in this  street. '
        imgExample="Martha's birthday"
        />

        <ReadingItem 
        title="Step 35"
        className="reading-item__image reading-item__image_35"
        rule1="1. Буквосочетание «ph» [f]"
        rule2="2. Запомни имя «Stephen» [ˈstiːv(ə)n]"
        example1='&bull;&nbsp;a photo &bull;&nbsp;a phrase '
        example2='&bull;&nbsp;Philip &bull;&nbsp;a phone &bull;&nbsp;Sophie'
        
        example4='&bull;&nbsp;Philip takes a photo.'
        example5='&bull;&nbsp;Stephen phones Philip.'
        example6="&bull;&nbsp;It is Sophie's phone."
        imgExample="Sophie will phone Philip."
        />
         <ReadingItem 
        title="Step 36"
        className="reading-item__image reading-item__image_36"
        rule1="1. Буквосочетание «ai» под ударением [ei]"
        rule2="2. Буквосочетание «ai» в безударном слоге [i]"
        example1='&bull;&nbsp;main &bull;&nbsp;rain &bull;&nbsp;a train'
        example2='&bull;&nbsp;Spain &bull;&nbsp;paint &bull;&nbsp;a grain'
        example3='&bull;&nbsp;Craig&bull;&nbsp;faint &bull;&nbsp; to maintain'

        example4='&bull;&nbsp;a mountain &bull;&nbsp;a fountain'
        example5='&bull;&nbsp;Craig paints.'
        example6='&bull;&nbsp;It rains.'
        imgExample="the train to Spain"
        
        />

        <ReadingItem 
        title="Step 37"
        className="reading-item__image reading-item__image_37"
        rule1="1. Согласная «Сс» перед i, e, y [s]"
        rule2="2. Запомни слова «to» [tuː]&nbsp;и «do»&nbsp;[duː]"
        example1='&bull;&nbsp;a dice &bull;&nbsp;to slice &bull;&nbsp;mice'
        example2='&bull;&nbsp;the cinema &bull;&nbsp;to cycle '
        example3='&bull;&nbsp;a bicycle&bull;&nbsp;Cindy &bull;&nbsp;Cyprus'

        example4=' &bull;&nbsp;Cindy has a cell phone.'
        example5=' &bull;&nbsp;Cindy is from Cyprus.'
        example6='&bull;&nbsp; It is the centre.'
        imgExample="Cindy cycles to the cinema."
        
        />
        <ReadingItem 
        title="Step 38"
        className="reading-item__image reading-item__image_38"
        rule1="1. Буквосочетание «ay» [ei]"
        rule2="2. Запомни «Мay» — май, «may»&nbsp;—&nbsp;можно "
        example1="&bull;&nbsp;May &bull;&nbsp;a day &bull;&nbsp;to lay &bull;&nbsp;Fay"
        example2="&bull;&nbsp;to pay &bull;&nbsp;to pray &bull;&nbsp;to sway"
        example4="&bull;&nbsp;Fay likes May days."
        example5="&bull;&nbsp;Fay may ride a bike."
        example6="&bull;&nbsp;Fay stays at home."
        imgExample="The kids play on Sundays."
        />
        <ReadingItem 
        title="Step 39"
        className="reading-item__image  reading-item__image_39"
        rule1="1. Буквосочетание «oa» [əʊ]"
        rule2="2. Запомни имя «Joanna»&nbsp;[dʒəʊanə]"
        example1='&bull;&nbsp;a goat &bull;&nbsp;a coat &bull;&nbsp;a boat'
        example2='&bull;&nbsp;a road &bull;&nbsp;to load &bull;&nbsp;a loan'
        example4='&bull;&nbsp;Joanna has a coat.'
        example5='&bull;&nbsp;Joanna has a boat.'
        example6='&bull;&nbsp;It is a road.'
        imgExample="A lorry is on the road."
        />
        <ReadingItem 
        title="Step 40"
        className="reading-item__image reading-item__image_40"
        rule1="1. Буквосочетание «ey» не под ударением [i:] "
        rule2="2. Буквосочетание «ey» под ударением [ei], но key [ki:]"
        example1='1. &bull;&nbsp;a key &bull;&nbsp;a turkey &bull;&nbsp;Turkey'
        example2='2. &bull;&nbsp;an alley&bull;&nbsp;they &bull;&nbsp;to obey'
        example4='&bull;&nbsp;Hey! I am from Turkey.'
        example5='&bull;&nbsp;Stacey, it is my key.'
        example6='&bull;&nbsp;Corey has a turkey.'
        imgExample="a park alley"
 />
        
        
       
    </ReadingLevelContainer>
  )
}

export default ReadingStep4