import React from 'react'

import './PresentSimpleTense.css'
import GrammarAccordion from '../../../GrammarAccordion/GrammarAccordion'
import TenseRule from '../../../GrammarRules/TenseRule/TenseRule'

function PresentSimpleTense() {
  return (
    <GrammarAccordion title="Present Simple">
      <TenseRule
        statement1="I / you / we / they + verb "
        statement2="He / she / it  + verb+s/es "
        statementEg1="I check my emails."
        statementEg2="He checks his emails."

        negative1="I / you / we / they + don't + verb "
        negative2="He / she / it  + doesn't + verb"
        negativeEg1="I don't check my emails."
        negativeEg2="He doesn't check his emails."

        question1=" do + I / you / we / they + verb "
        question2=" does + he / she / it  + verb"
        questionEg1="What time do you check your emails?"
        questionEg2="What time does he check his emails?"

        marker1="always;"
        marker2="usually;"
        marker3="often;"
        marker4="sometimes;"
        marker5="seldom;"
        marker6="rarely;"
        marker7="hardly ever;"
        marker8="never;"
        marker9="occasionally;"
        marker10="normally;"
        marker11="every day/week/etc.;"
        marker12="on Sundays/etc."
      >
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          daily routines and repeated actions;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          permanent states and facts;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          programmes and timetables;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          general truth and laws of nature;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          sports commentaries, review and newspaper headlines;
        </li>
        <li translate="no" className="tense-rule__using-item">
          <span className="using__tick">&#10003; </span>
          narrations (plots, jokes).
        </li>
      </TenseRule>
    </GrammarAccordion>
  )
}

export default PresentSimpleTense
