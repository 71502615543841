import React from 'react'
import './BinomialsSlide.css';

function BinomialsSlide(props) {
  return (
    <div className='binomial-slide'>
      <h3 className='binomial__title'translate="no" >{props.title}</h3>
      {/* <p className='binomial__explanation' translate="no" >{props.exp}</p> */}
      <div className={props.slideClassName} />
      <p translate="no"className='idiom__e-g'>e. g.</p>
      <div className='binomial__list'>
        <p className='binomial__list-item' translate="no" >{props.text1}</p>
      </div>
    </div>
  )
}

export default BinomialsSlide