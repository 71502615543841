import React from 'react'
import { useState } from 'react'

import './GrammarAccordion.css'

function GrammarAccordion(props) {
  const [contentShown, setContentShown] = useState(false)

  const handleShowContent = () => {
    setContentShown(!contentShown)
  }

  return (
    <div className="grammar-accordion">
      <div className="grammar-accordion__label" onClick={handleShowContent}>
        <p className="grammar-accordion__title" translate='no'>{props.title}</p>
        <span className="grammar-accordion__sign" translate="no">{!contentShown ? '>' : '<'}</span>
      </div>
      <div
        className={
          contentShown
            ? 'grammar-accordion__content  grammar-accordion__content_opened'
            : 'grammar-accordion__content'
        }
      >
        {props.children}
      </div>
    </div>
  )
}

export default GrammarAccordion
