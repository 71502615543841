import React from 'react'

import './SecondaryIntro.css'

function SecondaryIntro(props) {
  return (
    <section className='secondary-intro section'>
      <div className='section-container t-b-paddings' >
        {props.children}
      </div>
    </section>
  )
}

export default SecondaryIntro