import React from 'react'

import './TestsPage.css'
import SecondaryHeader from '../../Sections/SecondaryHeader/SecondaryHeader'
import SecondaryContent from '../../Sections/SecondaryContent/SecondaryContent'
import SecondaryIntro from '../../Sections/SecondaryIntro/SecondaryIntro'
import SecondaryFooter from '../../Sections/SecondaryFooter/SecondaryFooter'
import TestSection from '../../Sections/TestSection/TestSection'
import TestIntro from '../../Sections/TestIntro/TestIntro'

function TestsPage() {
  return (
    <div className='page'>
       <SecondaryHeader />
       <SecondaryContent>
         <SecondaryIntro>
          <TestIntro />
        </SecondaryIntro> 
        <TestSection />
       </SecondaryContent>
        <SecondaryFooter />
    </div>
  )
}

export default TestsPage