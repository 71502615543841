import React from 'react'

import './KidsVocabularyContainer.css';

import DataSlider from '../../Elements/DataSlider/DataSlider';
import KidsSlide from '../KidsSlide/KidsSlide';

import TransportRow from '../VocabularyRows/TransportRow/TransportRow';
import PetsRow from '../VocabularyRows/PetsRow/PetsRow';
import FruitRow from '../VocabularyRows/FruitRow/FruitRow'
import ColourRow from '../VocabularyRows/ColoursRow/ColoursRow'
import SliderTitle from '../../Elements/SliderTitle/SliderTitle';


function KidsVocabularyContainer() {
  return (
    <div className='Kids-vocabulary-container'>
    
    <SliderTitle title="COLORS"/>
    <ColourRow />

    <SliderTitle title="PETS"/>
    <PetsRow />

    <SliderTitle title="WILD ANIMALS"/>
    <DataSlider>
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_22"
            text="a zebra"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />

          <KidsSlide
            slideClassName="kids-slide kids-slide__image_21"
            text="a lion"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />

          <KidsSlide
            slideClassName="kids-slide kids-slide__image_23"
            text="a koala"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_24"
            text="a tiger"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_25"
            text="an elephant"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_26"
            text="monkeys"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_27"
            text="a giraffe"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_28"
            text="a rhino"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_29"
            text="flamingos"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_30"
            text="an iguana"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
    </DataSlider>

    <SliderTitle title="FRUIT"/>
    <FruitRow />

    <SliderTitle title="BREAKFAST"/>
    <DataSlider>
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_41"
            text="tea"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_42"
            text="milk"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_43"
            text="juice"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_44"
            text="a sandwich"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_45"
            text="an egg"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_46"
            text="cereal"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_47"
            text="buns"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_48"
            text="jam"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_49"
            text="honey"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_50"
            text="cottage cheese"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
    </DataSlider>

    <SliderTitle title="VERBS"/>
    <DataSlider>
         
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_82"
            text="to paint"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_83"
            text="to drink"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_84"
            text="to eat"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_85"
            text="to dance"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_86"
            text="to run"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_87"
            text="to read"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_88"
            text="to sleep"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_89"
            text="to cook"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_90"
            text="to play"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
           <KidsSlide
            slideClassName="kids-slide kids-slide__image_81"
            text="to swim"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
    </DataSlider>

    <SliderTitle title="FOR SCHOOL"/>
    <DataSlider>
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_61"
            text="a school bag"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_62"
            text="a book"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_63"
            text="copy-books"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_64"
            text="rulers"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_65"
            text="clips"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_66"
            text="a folder"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_67"
            text="rubbers"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_68"
            text="pencils"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_69"
            text="highlighters"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_70"
            text="a pen"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
    </DataSlider>
   
    <SliderTitle title="MEANS OF TRANSPORT"/>
    <TransportRow />  

  
    <SliderTitle title="NATURE"/>
    <DataSlider>
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_91"
            text="mountains"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_92"
            text="a forest"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_93"
            text="a desert"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_94"
            text="a river"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_95"
            text="a waterfall"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_96"
            text="a meadow"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_97"
            text="a volcano"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_98"
            text="a glacier"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_99"
            text="a flower"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
          <KidsSlide
            slideClassName="kids-slide kids-slide__image_100"
            text="stones"
            la="english"
            buttonClassName="kids-slide__sound-btn button_hidden"
          />
    </DataSlider> 
            
  
    </div>
  )
  
}

export default KidsVocabularyContainer
