import React from 'react'

import EducationalHeader from '../../../Sections/EducationalHeader/EducationalHeader'
import SecondaryFooter from '../../../Sections/SecondaryFooter/SecondaryFooter'
import SecondaryContent from '../../../Sections/SecondaryContent/SecondaryContent'
import GrammarIntroType1 from '../../../Sections/GrammarIntroType1/GrammarIntroType1'
import KidsVocabularyContainer2 from '../../../EducationSections/KidsVocabularyContainer2/KidsVocabularyContainer2'


function KidsVocabularyPage2() {
  return (
    <div className='page'>
       <EducationalHeader />
       <SecondaryContent>
       <GrammarIntroType1 
       title="KIDS' VOCABULARY"
       text =" Learning new words is very important for children when studying another language. It helps them understand and use the language better in both speaking and writing. With a larger vocabulary, they can express their thoughts more clearly and confidently. This also makes it easier for them to read and listen in the new language. Additionally, knowing more words helps children connect with the culture of the language they are learning. Overall, it boosts their learning abilities and prepares them to learn more languages in the future. 
        "
       />
        <KidsVocabularyContainer2 />
       </SecondaryContent>
        <SecondaryFooter />
    </div>
  )
}

export default KidsVocabularyPage2