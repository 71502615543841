import React from 'react'

import './SubjectsRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function SubjectsRow() {
  /* for blue */
  //   const blueTrack = document.getElementById('blueID')
  //   const blue = require('./blue.mp3')
  //   function bluePlay() {
  //     blueTrack.play()
  //   }
  //   /* for green */
  //   const greenTrack = document.getElementById('greenID')
  //   const green = require('./green.mp3')
  //   function greenPlay() {
  //     greenTrack.play()
  //   }

  //   /* for red */
  //   const redTrack = document.getElementById('redID')
  //   const red = require('./red.mp3')
  //   function redPlay() {
  //     redTrack.play()
  //   }
  //   /* for orange*/
  //   const orangeTrack = document.getElementById('orangeID')
  //   const orange = require('./orange.mp3')
  //   function orangePlay() {
  //     orangeTrack.play()
  //   }

  //   /* for white */
  //   const whiteTrack = document.getElementById('whiteID')
  //   const white = require('./white.mp3')
  //   function whitePlay() {
  //     whiteTrack.play()
  //   }

  //   /* for yellow */
  //   const yellowTrack = document.getElementById('yellowID')
  //   const yellow = require('./yellow.mp3')
  //   function yellowPlay() {
  //     yellowTrack.play()
  //   }

  //   /* for pink */
  //   const pinkTrack = document.getElementById('pinkID')
  //   const pink = require('./pink.mp3')
  //   function pinkPlay() {
  //     pinkTrack.play()
  //   }

  //   /* for brown */
  //   const brownTrack = document.getElementById('brownID')
  //   const brown = require('./brown.mp3')
  //   function brownPlay() {
  //     brownTrack.play()
  //   }

  //   /* for black */
  //   const blackTrack = document.getElementById('blackID')
  //   const black = require('./black.mp3')
  //   function blackPlay() {
  //     blackTrack.play()
  //   }

  //   /* for purple */
  //   const purpleTrack = document.getElementById('purpleID')
  //   const purple = require('./purple.mp3')
  //   function purplePlay() {
  //     purpleTrack.play()
  //   }

  return (
    <DataSlider>
      {/* for geography */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_geography">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Geography
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;Geography.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>
      {/* for english */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_english">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                English
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;English.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for maths */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_maths">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Maths
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;maths.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for literature */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_literature">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Literature
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;Literature.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>
      {/* for chemistry*/}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_chemistry">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Chemistry
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;Chemistry.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for ICT */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_ict">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                ICT
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;ICT.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for art */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_art">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Art
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;Art.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for biology */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_biology">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Biology
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;Biology.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>
      {/* for PE */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_pe">
            <div className="primary-school__short-info">
              <p translate="no" className="primary-school-slide__text">
                PE
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;PE.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for music */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_music">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                Music
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
              What is your favourite subject? My&nbsp;favourite subject
              is&nbsp;Music.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>
    </DataSlider>
  )
}
export default SubjectsRow
