import React from 'react'

import './ContextCard.css'

function ContextCard(props) {
  return (
    <div className='contextCard'>
        <div className={props.image}></div>
        <h4  translate="no" className="contextCard__title">{props.title}</h4>
        <div className="contextCard__keyWords">
            <p translate="no" className="contextCard__keyWord">&#183;&nbsp;{props.keyWord1}</p>
            <p translate="no" className="contextCard__keyWord">&#183;&nbsp;{props.keyWord2}</p>
            <p translate="no" className="contextCard__keyWord">&#183;&nbsp;{props.keyWord3}</p>
            <p translate="no" className="contextCard__keyWord">&#183;&nbsp;{props.keyWord4}</p>
            <p translate="no" className="contextCard__keyWord">&#183;&nbsp;{props.keyWord5}</p>
            {/* <p translate="no" className="contextCard__keyWord">&#183;{props.keyWord6}</p> */}
        </div>
        <p translate="no" className="contextCard__text">{props.text}</p>
    </div>
  )
}

export default ContextCard