import React from 'react'

import './TransportRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function TransportRow() {
  /* for helicopter */
  const helicopter = require('./helicopter.mp3')
  function helicopterPlay() {
    const helicopterTrack = document.getElementById('helicopterID')
    helicopterTrack.play()
  }

  /* for plane */
  const plane = require('./plane.mp3')
  function planePlay() {
    const planeTrack = document.getElementById('planeID')
    planeTrack.play()
  }

  /* for ballon */
  const ballon = require('./balloon.mp3')
  function ballonPlay() {
    const ballonTrack = document.getElementById('ballonID')
    ballonTrack.play()
  }

  /* for car */
  const car = require('./car.mp3')
  function carPlay() {
    const carTrack = document.getElementById('carID')
    carTrack.play()
  }

  /* for schoolbus */
  const schoolbus = require('./schoolbus.mp3')
  function schoolbusPlay() {
    const schoolbusTrack = document.getElementById('schoolbusID')
    schoolbusTrack.play()
  }

  /* for bike */
  const bike = require('./bike.mp3')
  function bikePlay() {
    const bikeTrack = document.getElementById('bikeID')
    bikeTrack.play()
  }

  /* for train */
  const train = require('./train.mp3')
  function trainPlay() {
    const trainTrack = document.getElementById('trainID')
    trainTrack.play()
  }

  /* for ship */
  const ship = require('./ship.mp3')
  function shipPlay() {
    const shipTrack = document.getElementById('shipID')
    shipTrack.play()
  }

  /* for boat */
  const boat = require('./boat.mp3')
  function boatPlay() {
    const boatTrack = document.getElementById('boatID')
    boatTrack.play()
  }

  /* for yacht */
  const yacht = require('./yacht.mp3')
  function yachtPlay() {
    const yachtTrack = document.getElementById('yachtID')
    yachtTrack.play()
  }

  return (
    <DataSlider>
      {/* for helicopter */}
      <div>
        <div
          className="kids-slide kids-slide__image_helicopter"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a helicopter
            </p>
            <button
              className="kids-slide__sound-btn"
              onClick={helicopterPlay}
            />
          </div>
          <audio id="helicopterID">
            <source src={helicopter} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for plane */}
      <div>
        <div
          className="kids-slide kids-slide__image_plane"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a plane
            </p>
            <button className="kids-slide__sound-btn" onClick={planePlay} />
          </div>
          <audio id="planeID">
            <source src={plane} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for ballon */}
      <div>
        <div
          className="kids-slide kids-slide__image_ballon"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a hot air balloоn
            </p>
            <button className="kids-slide__sound-btn" onClick={ballonPlay} />
          </div>
          <audio id="ballonID">
            <source src={ballon} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for car */}
      <div>
        <div
          className="kids-slide kids-slide__image_car"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a car
            </p>
            <button className="kids-slide__sound-btn" onClick={carPlay} />
          </div>
          <audio id="carID">
            <source src={car} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for schoolbus */}
      <div>
        <div
          className="kids-slide kids-slide__image_schoolbus"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a school bus
            </p>
            <button className="kids-slide__sound-btn" onClick={schoolbusPlay} />
          </div>
          <audio id="schoolbusID">
            <source src={schoolbus} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for bike */}
      <div>
        <div
          className="kids-slide kids-slide__image_bike"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a bicycle
            </p>
            <button className="kids-slide__sound-btn" onClick={bikePlay} />
          </div>
          <audio id="bikeID">
            <source src={bike} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for train */}
      <div>
        <div
          className="kids-slide kids-slide__image_train "
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a train
            </p>
            <button className="kids-slide__sound-btn" onClick={trainPlay} />
          </div>
          <audio id="trainID">
            <source src={train} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for ship */}
      <div>
        <div
          className="kids-slide kids-slide__image_ship"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a ship
            </p>
            <button className="kids-slide__sound-btn" onClick={shipPlay} />
          </div>
          <audio id="shipID">
            <source src={ship} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for boat */}
      <div>
        <div
          className="kids-slide kids-slide__image_boat"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a boat
            </p>
            <button className="kids-slide__sound-btn" onClick={boatPlay} />
          </div>
          <audio id="boatID">
            <source src={boat} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for yacht */}
      <div>
        <div
          className="kids-slide kids-slide__image_yacht "
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a yacht
            </p>
            <button className="kids-slide__sound-btn" onClick={yachtPlay} />
          </div>
          <audio id="yachtID">
            <source src={yacht} type="audio/mp3" />
          </audio>
        </div>
      </div>
    </DataSlider>
  )
}
export default TransportRow
