import React from 'react'

import './MainTitle.css'

function MainTitle() {
  return (
    <div className="main-title">
      <h1 className="main-title__title" translate='no'>Онлайн-преподаватель английского языка, Москва</h1>
      <a  className="main-title__subtitle" href="#teacher">Татьяна Викторовна, 12&nbsp;лет&nbsp;опыта <span className='main-title__span'>(подробнее)</span></a>
    </div>
  )
}

export default MainTitle
