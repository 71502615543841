import React from 'react'
import { Routes, Route } from 'react-router-dom'

import './App.css'
import HomePage from '../Pages/HomePage/HomePage'
import HomePageMenuOpen from '../Pages/HomePage/HomePageMenuOpen'
import TestsPage from '../Pages/TestsPage/TestsPage'
import FeedbackPage from '../Pages/FeedbackPage/FeedbackPage'
import PublicOfferPage from '../Pages/PublicOfferPage/PublicOfferPage'
import PrivacyPolicyPage from '../Pages/PrivacyPolicyPage/PrivacyPolicyPage'

/* Education */
import IrregularVerbsPage from '../Pages/EducationPages/IrregularVerbsPage/IrregularVerbsPage'
import ProverbsAndQuotesPage from '../Pages/EducationPages/ProverbsAndQuotesPage/ProverbsAndQuotesPage'
import ActiveVoicePage from '../Pages/EducationPages/ActiveVoicePage/ActiveVoicePage'
import BinomialsPage from '../Pages/EducationPages/BinomialsPage/BinomialsPage'
import LinkersPage from '../Pages/EducationPages/LinkersPage/LinkersPage'

/* primary school */
import PreschoolReadingPage from '../Pages/EducationPages/PreschoolReadingPage.js/PreschoolReadingPage'
import KidsRetellingPage from '../Pages/EducationPages/KidsRetellingPage/KidsRetellingPage'
import KidsVocabularyPage from '../Pages/EducationPages/KIdsVocabularyPage/KidsVocabularyPage'
import KidsVocabularyPage2 from '../Pages/EducationPages/KIdsVocabularyPage2/KidsVocabularyPage2'
import KidsVocabularyPage3 from '../Pages/EducationPages/KIdsVocabularyPage3/KidsVocabularyPage3'


function App() {
  return (
    <Routes>
      <Route  path="/" element={<HomePage />} />
      <Route  path="/menu-open" element={<HomePageMenuOpen />} />
      <Route  path="/tests" element={<TestsPage />} />
      <Route  path="/feedback" element={<FeedbackPage />} />
      <Route  path="/public-offer" element={<PublicOfferPage />} />
      <Route  path="/privacy-policy" element={<PrivacyPolicyPage />} />

      <Route  path="/irregular-verbs" element={<IrregularVerbsPage />} />
      <Route  path="/proverbs-and-quotes" element={<ProverbsAndQuotesPage />} />
      <Route  path="/active-voice" element={<ActiveVoicePage />} />
      <Route  path="/binomials" element={<BinomialsPage />} />
      <Route  path="/linkers" element={<LinkersPage />} />

      <Route  path="/preschool-reading" element={<PreschoolReadingPage />} />
      <Route  path="/kids-retelling" element={<KidsRetellingPage />} />
      <Route  path="/kids-vocabulary" element={<KidsVocabularyPage />} />
      <Route  path="/kids-vocabulary-2" element={<KidsVocabularyPage2 />} />
      <Route  path="/kids-vocabulary-3" element={<KidsVocabularyPage3 />} />
    </Routes>
  )
}

export default App