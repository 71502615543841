import React from 'react'

import './FeedbackIntro.css'
import SecondaryTitle from '../../Elements/SecondaryTitle/SecondaryTitle'

function FeedbackIntro() {
  return (
    <div>
      <SecondaryTitle title="YOUR FEEDBACK" />
      <div className="feedback-intro__main">
        <div className="feedback-intro__text-list">
        <p className="feedback-intro__text">Дорогие друзья! </p>
        <p className="feedback-intro__text">
        Я очень стараюсь, чтобы наши уроки были для вас и интересными, и полезными. <br />
        Ваша обратная связь очень важна для меня, так как она поможет мне стать лучше и вдохновит других учеников присоединиться к нашим занятиям.
        </p>
        <p className="feedback-intro__text">
          Большое спасибо за ваше время и&nbsp;участие!
        </p>

        <p className="feedback-intro__text">
          С уважением,
          <br />
          Татьяна Викторовна
        </p>
</div>
        <div className="feedback-intro__photo" />
      </div>
    </div>
  )
}

export default FeedbackIntro
