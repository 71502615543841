import React from 'react'

import './EnrolButton.css'

function EnrolButton() {
  return (
    <a 
    className="enrol-btn"
    href="https://t.me/TatianaParfe"
    target="_blank"
    rel="noreferrer"
    translate='no'
    
  >
    Записаться
  </a>
  )
}

export default EnrolButton