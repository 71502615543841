import React from 'react'
import { useState } from 'react'

import './ReadingAccordion.css'

function ReadingAccordion(props) {
  const [contentShown, setContentShown] = useState(false)

  const handleShowContent = () => {
    setContentShown(!contentShown)
  }

  return (
    <div className="reading-accordion">
      <div className="reading-accordion__label" onClick={handleShowContent}>
        <p className="reading-accordion__title" translate='no'>{props.title}</p>
        <span className="reading-accordion__sign" translate="no">{!contentShown ? '>' : '<'}</span>
      </div>
      <div
        className={
          contentShown
            ? 'reading-accordion__content  reading-accordion__content_opened'
            : 'reading-accordion__content'
        }
      >
        {props.children}
      </div>
    </div>
  )
}

export default ReadingAccordion
