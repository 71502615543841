import React from 'react'
import './ProverbSlide.css';

function ProverbSlide(props) {
  return (
    <div className='proverb-slide'>
      <h3 className='proverb__title'translate="no" >{props.title}</h3>
      <div className='proverb__extra-wrapper'>
      <div className={props.slideClassName} />
      <div className='proverb__list'>
        <p className='proverb__list-item' translate="no" >{props.text1}</p>
        <p className='proverb__list-item' translate="no" >{props.text2}</p>
        <p className='proverb__list-item' translate="no" >{props.text3}</p>
        <p className='proverb__list-item' translate="no" >{props.text4}</p>
        <p className='proverb__list-item' translate="no" >{props.text5}</p>
      </div>
      </div>
    </div>
  )
}

export default ProverbSlide