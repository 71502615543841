import React from 'react'

import './FeedbackMessages.css'
import FeedbackMessage from '../FeedbackMessage/FeedbackMessage'

function FeedbackMessages() {
  return (
    <div className="fb__messages">
      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_alexandra_pavlovna"
        text="По совету друзей, я записала своих детей на занятия к Татьяне Викторовне,
         и я осталась очень довольна результатом.
        У нас не только подтянулись оценки в школе до твёрдой 5,
        но и появился настоящий интерес к языку благодаря её знаниям, опыту,
         энергии, харизме и умению находить подход к ученикам."
      />
      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_anton"
        text="
        Хочу выразить благодарность Татьяне Викторовне за занятия.
         Замечательный преподаватель!"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_sasha"
        text="
        Если у вас есть желание эффективно выучить грамматику и подтянуть разговорный уровень английского языка
         – даже не сомневайтесь в выборе – занимайтесь с Татьяной Викторовной).
        Занятия всегда интересные и разнообразные, вы будете учиться с удовольствием
         и, конечно же, найдёте новых друзей в группе."
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_ivan"
        text="My English teacher, Tatiana Viktorovna, is simply amazing. She makes every lesson interesting and engaging.
        Her ability to explain complex grammar in an easy-to-understand way has really helped me improve my 
        knowledge.
        I feel very lucky to choose her as my teacher."
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_veronika"
        text="Самый лучший преподаватель! Всем рекомендую!❤️"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_masha_s"
        text="
        Мне очень нравится изучать английский язык с Татьяной Викторовной.
        Каждый урок что-то новое, даже если мы проходим большую тему,
         метод обучения меняется, так что я не успевала заскучать.
          Для тех, у кого есть проблемы с разговорной частью, это отличный шанс поверить в свои силы
           и стать более уверенным в себе! Однозначно рекомендую!"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__gaponova"
        text="Замечательные уроки английского языка!
         Татьяна Викторовна умеет найти подход к каждому, обучение проходит легко и продуктивно.
          Отличный результат после обучения!"
      />

     
      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_rudko"
        text="Я обучалась в группе по подготовке к внешним экзаменам.
        За очень короткий срок мы смогли охватить большой объем лексики и грамматики.
        Татьяна Викторовна всегда была внимателена к нашим вопросам и помогала разбираться в сложных темах. 
         А самое главное – это результат! Почти максимум. Спасибо большое!"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_madina"
        text="Татьяна Викторовна - это тот преподаватель, чьи уроки всегда ждешь с нетерпением.
         На занятиях очень комфортно. Английский язык стал моим любимым предметом.
         Спасибо!
    "
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_masha"
        text="Отличная подготовка к экзаменам, ответственный и добрый учитель.👍"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_margarita"
        text="По совету знакомых мои дети попали на дополнительные занятия в начальной школе.
         Татьяна Викторовна обладает удивительной способностью мотивировать детей, очень внимательная,
          компетентная и доброжелательная. Мы очень довольны их прогрессом."
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_lana"
        text="
        Я обратилась к Татьяне, когда появилась необходимость подтвердить уровень B2. За короткий срок мы прошли так много, по сути, горы перевернули. Так как я сама имею педагогическое образование, то могу точно сказать, что Татьяна - преподаватель с большой буквы. И у меня наконец-то пропал языковой барьер."
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_terent"
        text="
       Курсы Татьяны Викторовны дали мне огромный скачок в моём владении английского языка.
        Я с лёгкостью могу читать книги (в соответствии со своим уровнем), смотреть видео и понимать на слух речь другого языка.
Занятия проходят так легко, что не замечаешь времени.
С теплотой вспоминаю нашу группу и наши занятия.😊👍🙂"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_kira"
        text="
        The best teacher ever."
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_nikita"
        text="
      Я с большим удовольствием занимался в группе у Татьяны Викторовны довольно долгое время.
       Очень понравился подход и формат обучения! Могу порекомендовать всем!"
      />

      <FeedbackMessage
        classAuthor="fb-item__name fb-item__author_polina"
        text="
        Очень хорошие занятия, благодаря которым выучила английский до уровня В2.
         Помимо грамматики, много разговорного английского, интересных игр, просмотр видео и чтение книг."
      />

<FeedbackMessage
        classAuthor="fb-item__name fb-item__author_chaos"
        text="
        Мое обучение у Татьяны Викторовны началось давно, еще в 2010 году с уровня starter.
        За несколько лет я достигла уровня С2.
        Отличные занятия, такого соотношения цена-качество не найдете нигде.
         С улыбкой вспоминаю наши занятия и Татьяну Викторовну:)"
      />
    </div>
  )
}

export default FeedbackMessages
