import React from 'react'

import './MainIntro.css'
import MainTitle from '../../Elements/MainTitle/MainTitle'

function MainIntro() {
  return (
    <section className="main-intro section">
      <div className="section-container t-b-paddings">
        <MainTitle />
        <div className="main-intro__columns">
          <div className="main-intro__column main-intro__column_first">
            <div className="main-intro__photo">
              <p className="main-intro__photo-text" translate='no'>Я помогу вам освоить английский.</p>
              </div> 
            
          </div>

          <div className="main-intro__column main-intro__column_second">
            <h4 className="main-intro__pros-title" translate='no'>
              Преимущества онлайн-обучения
            </h4>
            <div className="main-intro__pros-list">
              <p className="main-intro__pros-text"> <span className='main-intro__pros-deco'/>
              Наши занятия проходят по учебным материалам издательств Cambridge, Macmillan, Pearson, National Geographic c применением коммуникативной методики, которая делает процесс обучения увлекательным и эффективным.
              </p>
              <p className="main-intro__pros-text"> <span className='main-intro__pros-deco'/>
             Уроки включают в себя расширение словарного запаса, чтение, письмо, углублённое изучение грамматики, аудио- и видеосопровождение урока, игровые элементы  для поддержания интереса, построение диалогов и многое другое.
              </p>
              <p className="main-intro__pros-text"> <span className='main-intro__pros-deco money-icon'/>Стоимость обучения составляет:
              </p>
              <p className="main-intro__pros-text text-align-left-and-margin" translate='no'> в&nbsp;группе&nbsp;— <span className="main-intro__pros-price">5500 ₽/мес (8 занятий);</span> </p> 
              <p className="main-intro__pros-text text-align-left-and-margin" translate='no'> индивидуально&nbsp;— <span className="main-intro__pros-price">1000 ₽/1 урок.</span> 
              </p>
              

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainIntro
