import React, { useState }  from 'react'

import './QA.css'

function QA() {
  const [contentShown1, setContentShown1] = useState(false)
  const [contentShown2, setContentShown2] = useState(false)
  const [contentShown3, setContentShown3] = useState(false)
  const [contentShown4, setContentShown4] = useState(false)

  function handleShowContent1() {
    setContentShown1(!contentShown1)
  }
  function handleShowContent2() {
    setContentShown2(!contentShown2)
  }
  function handleShowContent3() {
    setContentShown3(!contentShown3)
  }
  function handleShowContent4() {
    setContentShown4(!contentShown4)
  }

  return (
    <section className="q-and-a section">
      <div className="section-container t-b-paddings">
        <ul className="q-and-a__list">
          <li className="q-and-a__item">
            <button className="q-and-a__button" onClick={handleShowContent1}>
              <span className={contentShown1 ? "q-and-a__down q-and-a__up"  :"q-and-a__down" }/>
              Какие сервисы мы будем использовать на наших занятиях?
            </button>
            <div className={contentShown1 ? "q-and-a__block-open"  :"q-and-a__block-hidden" }>
              <p className="q-and-a__answer" translate='no'>Zoom для видеосвязи и Miro для совместной работы.</p>
            </div>
          </li>

          <li className="q-and-a__item">
            <button className="q-and-a__button" onClick={handleShowContent2}>
              <span className={contentShown2 ? "q-and-a__down q-and-a__up"  :"q-and-a__down" } />
              Будут ли домашние задания?
            </button>
            <div className={contentShown2 ? "q-and-a__block-open"  :"q-and-a__block-hidden" }>
              <p className="q-and-a__answer">
              Да, я буду задавать домашние задания в небольшом объёме для повторения и закрепления урока, но основная учебная нагрузка будет приходиться на занятия.
              </p>
            </div>
          </li>

          <li className="q-and-a__item">
            <button className={contentShown3 ? "q-and-a__button q-and-a__button_bold" : "q-and-a__button"} onClick={handleShowContent3}>
              <span className={contentShown3 ? "q-and-a__down q-and-a__up"  :"q-and-a__down" } />
              Сколько длится одно индивидуальное занятие?
            </button>
            <div className={contentShown3 ? "q-and-a__block-open"  :"q-and-a__block-hidden" }>
              <p className="q-and-a__answer">
              1 урок — 55 минут.
              </p>
            </div>
          </li>

          <li className="q-and-a__item">
            <button className="q-and-a__button" onClick={handleShowContent4}>
              <span className={contentShown4 ? "q-and-a__down q-and-a__up"  :"q-and-a__down" } />
              В какие дни проходят занятия?
            </button>
            <div className={contentShown4 ? "q-and-a__block-open"  :"q-and-a__block-hidden" }>
              <p className="q-and-a__answer">
              Я работаю с понедельника по субботу. Дни и время занятий согласовываются перед их началом, и далее мы следуем выбранному расписанию на протяжении учебного года. </p>


            </div>
          </li>

          {/* <li className="q-and-a__item">
            <button className="q-and-a__button" onClick={handleShowContent4}>
              <span className={contentShown4 ? "q-and-a__down q-and-a__up"  :"q-and-a__down" } />
              Могут ли студенты и взрослые записаться на занятия или они только для школьников?
            </button>
            <div className={contentShown4 ? "q-and-a__block-open"  :"q-and-a__block-hidden" }>
              <p className="q-and-a__answer">Занятия открыты как для школьников, так и для взрослых,
              группы формируются отдельно по возрастным категриям. <br /> Взрослым также доступны индивидуальные занятия.</p>
            </div>
          </li> */}

        </ul>
      </div>
    </section>
  )
}

export default QA
