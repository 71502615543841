import React, { useState } from 'react'

import './FeedbackSection.css'
import FeedbackForm from '../../Elements/FeedbackForm/FeedbackForm'
import FeedbackMessages from '../../Elements/FeedbackMessages/FeedbackMessages'

function FeedbackSection() {
  const [firstActive, setFirstActive] = useState(true)
  const [secondActive, setSecondtActive] = useState(false)

  function handleFirstActive() {
    setSecondtActive(false)
    setFirstActive(true)
  }
  function handleSecondActive() {
    setSecondtActive(true)
    setFirstActive(false)
  }

  return (
    <section>
      <div className="bk-s section">
        <div className="section-container bookmarks ">
          <button
            className={firstActive ? 'bookmark bookmark_active' : 'bookmark '}
            onClick={handleFirstActive}
          >
            Читать отзывы
          </button>
          <button
            className={secondActive ? 'bookmark bookmark_active' : 'bookmark '}
            onClick={handleSecondActive}
          >
            Оставить отзыв
          </button>
        </div>
      </div>

      <div className="feedback-main section">
        <div className="section-container t-b-paddings">
          {firstActive && <FeedbackMessages />}
          {secondActive && <FeedbackForm />}
        </div>
      </div>
    </section>
  )
}

export default FeedbackSection
