import React from 'react'

import './FeedbackPage.css'
import SecondaryHeader from '../../Sections/SecondaryHeader/SecondaryHeader'
import SecondaryContent from '../../Sections/SecondaryContent/SecondaryContent'
import SecondaryIntro from '../../Sections/SecondaryIntro/SecondaryIntro'
import SecondaryFooter from '../../Sections/SecondaryFooter/SecondaryFooter'
import FeedbackSection from '../../Sections/FeedbackSection/FeedbackSection'
import FeedbackIntro from '../../Sections/FeedbackIntro/FeedbackIntro'

function FeedbackPage() {
  return (
    <div className="page">
      <SecondaryHeader />
      <SecondaryContent>
        <SecondaryIntro>
          <FeedbackIntro />
        </SecondaryIntro>
        <FeedbackSection />
      </SecondaryContent>
      <SecondaryFooter />
    </div>
  )
}

export default FeedbackPage
