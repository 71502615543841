import React from 'react'

import './MainTeacher.css'

function MainTeacher() {
  return (
    <section id="teacher" className="main-teacher section">
      <div className="section-container t-b-paddings">
			<p className='main-teacher__text main-teacher__text_greetings' translate='no'>Добрый день, дорогие ученики и&nbsp;их&nbsp;родители!</p>
        <p className='main-teacher__text' translate='no'>
        Меня зовут Татьяна Викторовна. Более 10 лет я преподавала в&nbsp;вузе и вела
          курсы для разных возрастных групп, включая подготовку к различным экзаменам, в том числе к EГЭ и ОГЭ.
          В настоящий момент я провожу онлайн-занятия для детей и взрослых.
          Для меня важен каждый ученик. Я стремлюсь передать максимум знаний и подхожу к успеваемости своих учеников с той же ответственностью, как и к успеваемости своих собственных детей.
          Ребята, которых я обучала, показали отличные результаты: высокие баллы при сдаче экзаменов, победы на конкурсах, свободный английский на собеседованиях и т.&nbsp;д.
          В&nbsp;новом учебном году приглашаю и вас на свои занятия.

        </p>
      </div>
    </section>
  )
}

export default MainTeacher
