import React from 'react'

import './Advert.css'

function Advert() {
  return (
    <section className='advert section' id="groups">
    <div className='section-container t-b-paddings' >
        <p className='advert__text' translate='no'>Приглашаю новых учеников записаться в 2024/25 учебном году!</p>
        {/* <p className='advert__text advert__text_smaller' translate='no'>Звоните сейчас, чтобы гарантированно забронировать место!</p> */}
    </div>
  </section>
  )
}

export default Advert