import React from 'react'
import { NavLink } from 'react-router-dom'

import './MainHeader.css'
import HeaderLogoArea from '../../Elements/HeaderLogoArea/HeaderLogoArea'
import EnrolButton from '../../Elements/EnrolButton/Enrolbutton'

function MainHeader(props) {
  return (
    <div className="main-header section">
      <div className="section-container main-header__container">
        <HeaderLogoArea />

        <div className="main-header__nav-links">
          {/* <button className="main-header__nav-link">Карточки</button> */}
          <NavLink
            translate="no"
            to="/feedback"
            className="main-header__nav-link"
          >
            Отзывы
          </NavLink>
          <NavLink translate="no" to="/tests" className="main-header__nav-link">
            Тестирование
          </NavLink>
          <a
            className="main-header__nav-link main-header__nav-link_groups"
            href="#groups"
            translate="no"
          >
            Занятия
          </a>
          <a
            className="main-header__nav-link main-header__nav-link_groups"
            href="#teacher"
            translate="no"
          >
            Обо мне
          </a>

          <button className="main-header__nav-link" onClick={props.menuOpenHandler}>Полезное</button>
          <a className="main-header__nav-link " href="#contacts">
            Контакты
          </a>
          
          
          
          <a
            href="https://rutube.ru/channel/38625290/"
            target="_blank"
            rel="noreferrer"
            className="main-header__nav-link main-header__nav-link_rutube"
            translate="no"
          >
            RUTUBE
          </a>
          {/* <a
            href="https://vk.com/english_in_moscow_online"
            target="_blank"
            rel="noreferrer"
            className="main-header__nav-link main-header__nav-link_rutube"
            translate="no"
          >
            VK
          </a> */}
        </div>

        <div className="main-header__enrol-btn-container">
          <p className="main-header__phone-number">+7 (926) 33 77 444</p>
          <EnrolButton />
        </div>
      </div>
    </div>
  )
}

export default MainHeader
