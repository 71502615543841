import React from 'react'

import './PetsRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function PetsRow() {
  /* for dog */
  const dog = require('./dog.mp3')
  function dogPlay() {
    const dogTrack = document.getElementById('dogID')
    dogTrack.play()
  }

  /* for puppy */
  const puppy = require('./puppy.mp3')
  function puppyPlay() {
    const puppyTrack = document.getElementById('puppyID')
    puppyTrack.play()
  }

  /* for fish */
  const fish = require('./fish.mp3')
  function fishPlay() {
    const fishTrack = document.getElementById('fishID')
    fishTrack.play()
  }

  /* for hamster*/
  const hamster = require('./hamster.mp3')
  function hamsterPlay() {
    const hamsterTrack = document.getElementById('hamsterID')
    hamsterTrack.play()
  }

  /* for cat */
  const cat = require('./cat.mp3')
  function catPlay() {
    const catTrack = document.getElementById('catID')
    catTrack.play()
  }

  /* for kitten */
  const kitten = require('./kitten.mp3')
  function kittenPlay() {
    const kittenTrack = document.getElementById('kittenID')
    kittenTrack.play()
  }

  /* for guinea */
  const guinea = require('./guinea.mp3')
  function guineaPlay() {
    const guineaTrack = document.getElementById('guineaID')
    guineaTrack.play()
  }

  /* for parrot */
  const parrot = require('./parrot.mp3')
  function parrotPlay() {
    const parrotTrack = document.getElementById('parrotID')
    parrotTrack.play()
  }

  /* for chinchilla */
  const chinchilla = require('./chinchilla.mp3')
  function chinchillaPlay() {
    const chinchillaTrack = document.getElementById('chinchillaID')
    chinchillaTrack.play()
  }

  /* for rabbit */
  
  const rabbit = require('./rabbit.mp3')
  function rabbitPlay() {
    const rabbitTrack = document.getElementById('rabbitID')
    rabbitTrack.play()
  }

  return (
    <DataSlider>
      {/* for dog */}
      <div>
        <div
          className="kids-slide kids-slide__image_dog"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a dog
            </p>
            <button className="kids-slide__sound-btn" onClick={dogPlay} />
          </div>
          <audio id="dogID">
            <source src={dog} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for puppy */}
      <div>
        <div
          className="kids-slide kids-slide__image_puppy"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a puppy
            </p>
            <button className="kids-slide__sound-btn" onClick={puppyPlay} />
          </div>
          <audio id="puppyID">
            <source src={puppy} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for fish */}
      <div>
        <div
          className="kids-slide kids-slide__image_fish"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a fish
            </p>
            <button className="kids-slide__sound-btn" onClick={fishPlay} />
          </div>
          <audio id="fishID">
            <source src={fish} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for hamster */}
      <div>
        <div
          className="kids-slide kids-slide__image_hamster"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a hamster
            </p>
            <button className="kids-slide__sound-btn" onClick={hamsterPlay} />
          </div>
          <audio id="hamsterID">
            <source src={hamster} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for cat */}
      <div>
        <div
          className="kids-slide kids-slide__image_cat"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a cat
            </p>
            <button className="kids-slide__sound-btn" onClick={catPlay} />
          </div>
          <audio id="catID">
            <source src={cat} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for kitten */}
      <div>
        <div
          className="kids-slide kids-slide__image_kitten"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a kitten
            </p>
            <button className="kids-slide__sound-btn" onClick={kittenPlay} />
          </div>
          <audio id="kittenID">
            <source src={kitten} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for guinea */}
      <div>
        <div
          className="kids-slide kids-slide__image_guinea"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a guinea pig
            </p>
            <button className="kids-slide__sound-btn" onClick={guineaPlay} />
          </div>
          <audio id="guineaID">
            <source src={guinea} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for parrot */}
      <div>
        <div
          className="kids-slide kids-slide__image_parrot"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a parrot
            </p>
            <button className="kids-slide__sound-btn" onClick={parrotPlay} />
          </div>
          <audio id="parrotID">
            <source src={parrot} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for chinchilla */}
      <div>
        <div
          className="kids-slide kids-slide__image_chinchilla"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a chinchilla
            </p>
            <button
              className="kids-slide__sound-btn"
              onClick={chinchillaPlay}
            />
          </div>
          <audio id="chinchillaID">
            <source src={chinchilla} type="audio/mp3" />
          </audio>
        </div>
      </div>

      {/* for rabbit */}
      <div>
        <div
          className="kids-slide kids-slide__image_rabbit"
          style={{ width: 280 }}
        >
          <div className="kids-slide__card-info">
            <p className="kids-slide__text" translate="no">
              a rabbit
            </p>
            <button className="kids-slide__sound-btn" onClick={rabbitPlay} />
          </div>
          <audio id="rabbitID">
            <source src={rabbit} type="audio/mp3" />
          </audio>
        </div>
      </div>
    </DataSlider>
  )
}
export default PetsRow
