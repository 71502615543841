import React from 'react'

import './ProverbsForEssays.css';
import ProverbSlide from '../ProverbSlide/ProverbSlide'

function ProverbsForEssays(props) {
  return (
    <div className={props.className}>
      

      <div className='proverbs-container'>
      
      <ProverbSlide
            title="Education"
            text1="&#8727; It is never too late to learn."
            text2="&#8727; Live and learn."
            text3="&#8727; Experience is the mother of wisdom."
            text4="&#8727; Knowledge has bitter roots but sweet fruits."
            slideClassName="proverb__image proverb__image_8"
          />
          <ProverbSlide
            title="Progress and success"
            text1="&#8727; Practice makes perfect."
            text2="&#8727; A stitch in time saves nine."
            text3="&#8727; “Success is one percent inspiration, ninety-nine percent perspiration.” – Thomas Alva Edison"
            text4="&#8727; “Success is the child of audacity.” – Benjamin Disraeli"
            slideClassName="proverb__image proverb__image_9"
          />
      <ProverbSlide
            title="Friendship"
            text1="&#8727; A true friend is one soul in two bodies."
            text2="&#8727; Life is better with true friends."
            text3="&#8727; A friend in need is a friend indeed."
            text4="&#8727; Best friends make good times better and hard times easier."
            slideClassName="proverb__image proverb__image_1"
          />
          <ProverbSlide
            title="Home"
            text1="&#8727; There is no place like home."
            text2="&#8727; My house is my castle."
            text3="&#8727; Home is where our heart is."
            text4="&#8727; Every dog is a lion at home."
            text5="&#8727; East or west – home is the best."
            slideClassName="proverb__image proverb__image_2"
          />
          <ProverbSlide
            title="Family"
            text1="&#8727; “The family is one of nature’s masterpieces.” – George&nbsp;Santayana"
            text2="&#8727; Blood is thicker than water."
            text3="&#8727; Like father, like son."
            text4="&#8727; The apple doesn’t fall far from the tree."
            slideClassName="proverb__image proverb__image_3"
          />
          <ProverbSlide
            title="Pets"
            text1="&#8727; “You become responsible forever for what you have tamed.” ― Antoine de Saint-Exupéry"
            text2="&#8727; A dog is man's best friend."
            text3="&#8727; A dog is the only thing on earth that loves you more than he loves himself. – Josh Billings"
            slideClassName="proverb__image proverb__image_4"
          />
          <ProverbSlide
            title="Health"
            text1="&#8727; Health is better than wealth."
            text2="&#8727; An apple a day keeps the doctor away."
            text3="&#8727; Early to bed and early to rise makes a man healthy, wealthy and wise."
            text4="&#8727;Prevention is better than cure."
            slideClassName="proverb__image proverb__image_5"
          />
          <ProverbSlide
            title="Time"
            text1="&#8727; Time will tell."
            text2="&#8727; A stitch in time saves nine."
            text3="&#8727; An hour in the morning is worth two in the evening."
            text4="&#8727; Everything is good in its season."
            slideClassName="proverb__image proverb__image_6"
          />
          <ProverbSlide
            title="Travel"
            text1="&#8727; So many countries, so many customs."
            text2="&#8727; When at Rome, do as the Romans do."
            text3="&#8727; Who will go far must go slow."
            text4="&#8727; The world is a book, and those who do not travel read only one page."
            slideClassName="proverb__image proverb__image_7"
          />
          
         
          <ProverbSlide
            title="Happiness and good luck"
            text1="&#8727; Happiness isn't a&nbsp;destination. It is a method of life."
            text2="&#8727; Happiness takes no account of time."
            text3="&#8727; No joy without alloy."
            text4="&#8727; He is happy that thinks himself so."
            slideClassName="proverb__image proverb__image_10"
          />
      </div>
    </div>
  )
}

export default ProverbsForEssays
