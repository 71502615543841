import React from 'react'

import './MainShareLinks.css'

function MainShareLinks() {

	function telegramShare() {
    window.open('https://telegram.me/share/url?url=https://english.moscow')
  }
  
  function whatsappShare() {
    window.open('https://wa.me/send?text=Курсы английского языка онлайн с Татьяной Викторовной https://english.moscow')
  }

  function viberShare() {
    window.open('viber://forward?text=https://english.moscow')
  }

  function vkShare() {
    window.open('https://vkontakte.ru/share.php?url=https://english.moscow')
  }

  return (
    <section className="main-share-links section">
      <div className="section-container t-b-paddings">
        <p className="main-share-links__text">
          Поделиться ссылкой на сайт c&nbsp;семьёй и друзьями:
        </p>
				<div className="main-share-links__buttons" >
           <button onClick={telegramShare} className="main-share-links__button share-telegram" />
           <button onClick={whatsappShare} className="main-share-links__button share-whatsapp" />
           <button onClick={viberShare} className="main-share-links__button share-viber" />
           <button onClick={vkShare} className="main-share-links__button share-vk" />
       </div>
      </div>
    </section>
  )
}

export default MainShareLinks
