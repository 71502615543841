import React from 'react'
import { NavLink } from 'react-router-dom'

import './PublicOffer.css'

function PublicOffer() {
  return (
    <div className="section">
      <div className="public-doc section-container ">
        <NavLink className="doc__back-link" to="/">&#8592; На главную</NavLink>
        <h2 className="doc__title">
          Публичная оферта о предоставлении платных образовательных услуг в группах
        </h2>

        <h3 className="doc__subtitle">1 Общие положения</h3>

        <p className="doc__item">
          1.1 Настоящая оферта является публичным предложением 
          Парфенюк Татьяны
          Викторовны заключить договор на оказание образовательных услуг на
          условиях, изложенных ниже.
        </p>

        <p className="doc__item">1.2 Договор становится действительным с момента оплаты услуг.</p>

        <p className="doc__item">
          1.3 Срок действия договора составляет один месяц и автоматически
          перезаключается при оплате за следующий месяц.
        </p>

        <h3 className="doc__subtitle">2 Порядок оказания услуг</h3>

        <p className="doc__item">2.1 Занятия проходят онлайн.</p>

        <p className="doc__item">
          2.2 Уроки проводятся два раза в неделю в оговоренное время и дни
          недели, кроме праздничных дней.
        </p>

        <h3 className="doc__subtitle">3 Стоимость услуг и порядок расчетов</h3>

        <p className="doc__item">3.1 Стоимость услуг составляет 5500 рублей в месяц.</p>

        <p className="doc__item">
          3.2 Оплата производится до 15 числа того же месяца, в котором
          оказываются услуги.
        </p>

        <p className="doc__item">3.3 Оплата за пропущенные учеником занятия не компенсируется.</p>

        <h3 className="doc__subtitle">4 Порядок расторжения договора</h3>

        <p className="doc__item">4.1 Договор может быть расторгнут по инициативе любой из сторон.</p>

        <p className="doc__item">
          4.2 В случае расторжения договора оплата за уроки будет возвращена в
          соответствии с количеством оставшихся занятий в месяце.
        </p>

        <h3 className="doc__subtitle">5 Согласие родителей или законных представителей</h3>

        <p className="doc__item">
          5.1 Подтверждением согласия на обучение несовершеннолетнего является
          оплата услуг обучения со стороны родителя или законного представителя.
        </p>

        <h3 className="doc__subtitle">6 Прочие условия</h3>
        <p className="doc__item" translate='no'>
          6.1 Политика конфиденциальности, размещенная по адресу:  <NavLink className="doc__inner-link" to="/privacy-policy">www.english.moscow/privacy-policy</NavLink>, является неотъемлемой частью данного договора.
        </p>

        <p className="doc__item">
          6.2 Принятием условий данной оферты заказчик подтверждает свое
          согласие с условиями настоящего договора.
        </p>

        <h3 className="doc__subtitle">7 Контактная информация</h3>

        <p className="doc__item">Татьяна Викторовна </p>
        <p className="doc__item">Телефон: +7 (926) 33-77-444</p>

        <p className="doc__item" translate='no'>Email: info@english.moscow</p>

        <p className="doc__item doc__date">Дата публикации: 01.06.2024</p>
      </div>
    </div>
  )
}

export default PublicOffer
