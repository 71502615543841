import React from 'react'

import './HeaderLogoArea.css'

function HeaderLogoArea() {
  return (
    <div className="logo-area">
      <div className="logo-image" />
      <p translate='no' className="logo-text">Study with Tatiana Parfe</p>
    </div>
  )
}

export default HeaderLogoArea
