import React from 'react'

import './ReadingStep1.css'
import ReadingLevelContainer from '../ReadingLevelContainer/ReadingLevelContainer'
import ReadingItem from '../ReadingItem/ReadingItem'

function ReadingStep1() {
  return (
    <ReadingLevelContainer>
        <ReadingItem
        title="Step 1"
        className="reading-item__image reading-item__image_1"
        rule1="1. Согласные «Pp» [p], «Tt» [t], «Nn»&nbsp;[n]"
        rule2="2. Гласная «&#8544;i» в закрытом слоге [i]"
        example1='&bull;&nbsp;a pin &bull;&nbsp;a pit &bull;&nbsp;a tip'
        example2='&bull;&nbsp;a pip &bull;&nbsp;a tit &bull;&nbsp;a tin'
        imgExample="a tit"
         />
        <ReadingItem 
        title="Step 2"
        className="reading-item__image reading-item__image_2"
        rule1="1. Согласные «Kk» [k], «Mm» [m], «Ll»&nbsp;[l]"
        rule2="2. Гласная «Aa» в закрытом слоге [æ]"
        example1='&bull;&nbsp;a pan &bull;&nbsp;a man &bull;&nbsp;a pal'
        example2='&bull;&nbsp;Pam &bull;&nbsp;a map &bull;&nbsp;a lamp'
        imgExample="a map"
        
        />
        <ReadingItem 
        title="Step 3"
        className="reading-item__image reading-item__image_3"
        rule1="1. Согласные «Dd» [d], «Hh» [h]"
        rule2="2. Гласная «Oo» в закрытом слоге [ɔ]"
        example1='&bull;&nbsp;a pot &bull;&nbsp;a dot &bull;&nbsp;not'
        example2='&bull;&nbsp;a doll &bull;&nbsp;hot &bull;&nbsp;a lot'
        example3='&bull;&nbsp;on &bull;&nbsp;a pond &bull;&nbsp;a top'
        imgExample="a pond"
        
        />
        <ReadingItem 
        title="Step 4"
        className="reading-item__image reading-item__image_4"
        rule1="1. Согласные «Ss» [s], «Zz» [z]"
        rule2="2. Буквосочетание «oo»  [u:]"
        example1='&bull;&nbsp;a zoo &bull;&nbsp;too &bull;&nbsp;tool &bull;&nbsp;a hood'
        example2='&bull;&nbsp;a pool &bull;&nbsp;soon &bull;&nbsp;a spoon'
        example3='&bull;&nbsp;a mood &bull;&nbsp;zoom &bull;&nbsp;a roof'
        imgExample="a zoo"
        />
        <ReadingItem 
        title="Step 5"
        className="reading-item__image reading-item__image_5"
        rule1="1. Согласная «Bb» [b] "
        rule2="2. Буквосочетание «ook»  [uk]"
        example1='&bull;&nbsp;a book &bull;&nbsp;a hook &bull;&nbsp;to look'
        example2='&bull;&nbsp;a bob &bull;&nbsp;to sob &bull;&nbsp;a bot'
        example3='&bull;&nbsp;a bat &bull;&nbsp;to ban &bull;&nbsp;a band'
        imgExample="a book"
        />
         <ReadingItem 
        title="Step 6"
        className="reading-item__image reading-item__image_6"
        rule1=" 1. Согласные «Xx» [ks], «Ww» [w], «Rr»&nbsp;[r] "
        rule2=" 2. Гласная «Ee» в закрытом слоге [e]"
        example1='&bull;&nbsp;a well &bull;&nbsp;to spell &bull;&nbsp;to sell'
        example2='&bull;&nbsp;Rex &bull;&nbsp;red &bull;&nbsp;a pen &bull;&nbsp;men'
        example3='&bull;&nbsp;a pet &bull;&nbsp;a set &bull;&nbsp;wet &bull;&nbsp;to rest'
        imgExample="a well"
        />
        <ReadingItem 
        title="Step 7"
        className="reading-item__image reading-item__image_7"
        rule1="1. Согласная «Ff» [f]  "
        rule2="2. Буквосочетание «ee» [i:]"
        example1='&bull;&nbsp;to feel &bull;&nbsp;to feed &bull;&nbsp;feet'
        example2='&bull;&nbsp;free &bull;&nbsp;a week &bull;&nbsp;a seed &bull;&nbsp;a fee '
        example3='&bull;&nbsp;to see &bull;&nbsp;to meet &bull;&nbsp;to need'
        imgExample="a tree"
        />
        <ReadingItem 
        title="Step 8"
        className="reading-item__image reading-item__image_8"
        rule1="1. Согласная «Vv» [v] "
        rule2="2. Буквосочетание «ea»  [i:]"
        example1='&bull;&nbsp;tea &bull;&nbsp;a pea &bull;&nbsp;to lead &bull;&nbsp;lean'
        example2='&bull;&nbsp;veal &bull;&nbsp;to deal &bull;&nbsp;to dream'
        example3='&bull;&nbsp;to steal &bull;&nbsp;a seal &bull;&nbsp;a meal '
        imgExample="a seal"
        />
        <ReadingItem 
        title="Step 9"
        className="reading-item__image reading-item__image_9"
        rule1="1. Согласная «Cc» перед «a», «o», «u» и&nbsp;согласными [k] "
        rule2="2. Гласная «Uu» в закрытом слоге [ʌ], но&nbsp;«put»&nbsp;[pʊt]"
        example2=' &bull;&nbsp;a cot &bull;&nbsp;a cod &bull;&nbsp;to cut'
        example1='&bull;&nbsp;a cat &bull;&nbsp;a cap &bull;&nbsp;a can'
        example3='&bull;&nbsp;a nut &bull;&nbsp;a hut &bull;&nbsp;but'
        imgExample="a hut"
        />
        <ReadingItem 
        title="Step 10"
        className="reading-item__image reading-item__image_10"
        rule1="1. Согласная «Jj» [dʒ]"
        rule2="2. Буквосочетание «ng» [ŋ]"
        example1='&bull;&nbsp;jam &bull;&nbsp;jump &bull;&nbsp;just &bull;&nbsp;a jet'
        example2='&bull;&nbsp;king &bull;&nbsp;to sing &bull;&nbsp;singing'
        example3='&bull;&nbsp;long &bull;&nbsp;a song &bull;&nbsp;strong'
        imgExample="jam"
        />
    </ReadingLevelContainer>
  )
}

export default ReadingStep1