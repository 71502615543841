import React from 'react'
import { NavLink } from 'react-router-dom'

import './ToMenuButton.css'

function ToMenuButton() {
  return (
    <NavLink to="/menu-open" className="secondaryHeader__backButton">
    В меню
  </NavLink>
  )
}

export default ToMenuButton