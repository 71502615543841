import React from 'react'

import './JobsRow.css'
import DataSlider from '../../../Elements/DataSlider/DataSlider'

function JobsRow() {
  /* for blue */
  //   const blueTrack = document.getElementById('blueID')
  //   const blue = require('./blue.mp3')
  //   function bluePlay() {
  //     blueTrack.play()
  //   }
  //   /* for green */
  //   const greenTrack = document.getElementById('greenID')
  //   const green = require('./green.mp3')
  //   function greenPlay() {
  //     greenTrack.play()
  //   }

  //   /* for red */
  //   const redTrack = document.getElementById('redID')
  //   const red = require('./red.mp3')
  //   function redPlay() {
  //     redTrack.play()
  //   }
  //   /* for orange*/
  //   const orangeTrack = document.getElementById('orangeID')
  //   const orange = require('./orange.mp3')
  //   function orangePlay() {
  //     orangeTrack.play()
  //   }

  //   /* for white */
  //   const whiteTrack = document.getElementById('whiteID')
  //   const white = require('./white.mp3')
  //   function whitePlay() {
  //     whiteTrack.play()
  //   }

  //   /* for yellow */
  //   const yellowTrack = document.getElementById('yellowID')
  //   const yellow = require('./yellow.mp3')
  //   function yellowPlay() {
  //     yellowTrack.play()
  //   }

  //   /* for pink */
  //   const pinkTrack = document.getElementById('pinkID')
  //   const pink = require('./pink.mp3')
  //   function pinkPlay() {
  //     pinkTrack.play()
  //   }

  //   /* for brown */
  //   const brownTrack = document.getElementById('brownID')
  //   const brown = require('./brown.mp3')
  //   function brownPlay() {
  //     brownTrack.play()
  //   }

  //   /* for black */
  //   const blackTrack = document.getElementById('blackID')
  //   const black = require('./black.mp3')
  //   function blackPlay() {
  //     blackTrack.play()
  //   }

  //   /* for purple */
  //   const purpleTrack = document.getElementById('purpleID')
  //   const purple = require('./purple.mp3')
  //   function purplePlay() {
  //     purpleTrack.play()
  //   }

  return (
    <DataSlider>
      {/* for a doctor */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_doctor">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a doctor
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
              What is her job? She&nbsp;is&nbsp;a&nbsp;doctor.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for a pilot */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_pilot">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a pilot
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is his job? He&nbsp;is&nbsp;a&nbsp;pilot.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for an air hostess */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_hostess">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                an air hostess
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is her job? She&nbsp;is&nbsp;an&nbsp;air&nbsp;hostess.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for a chef */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_chef">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a chef
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is his job? He&nbsp;is&nbsp;a&nbsp;chef.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

         {/* for a teacher */}
         <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_teacher">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a teacher
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is her job? She&nbsp;is&nbsp;a&nbsp;teacher.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      {/* for an engineer */}
      <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_engineer">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
              an engineer
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
            What is his job? &nbsp;He&nbsp;is&nbsp;an&nbsp;engineer.&nbsp;
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

        {/* for a vet */}
        <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_vet">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a vet
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is her job? She&nbsp;is&nbsp;a&nbsp;vet.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>


       {/* for a spaceman */}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_spaceman">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
              a spaceman
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is his job? &nbsp;He&nbsp;is&nbsp;a&nbsp;spaceman.&nbsp;
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

       {/* for lab assistent*/}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_lab">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a lab assistant
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p  translate="no" className="primarySchoolRow__main-text">
            What is her job? She&nbsp;is&nbsp;a&nbsp;lab&nbsp;assistant.
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

      
       {/* for a programmer*/}
       <div>
        <div className="primary-school-slide " style={{ width: 280 }}>
          <div className="primary-school-photo family-slide__image_programmer">
            <div className="primary-school__short-info">
              <p className="primary-school-slide__text" translate="no">
                a programmer
              </p>
              <button className="kids-slide__sound-btn" />
            </div>
          </div>

          <div className="primarySchoolRow__main-text-div">
            <p translate="no" className="primarySchoolRow__main-text">
            What is his job? &nbsp;He&nbsp;is&nbsp;an&nbsp;programmer.&nbsp;
            </p>
            <button className="kids-slide__sound-btn" />
          </div>

          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
          {/* <audio id="blueID">
            <source src={blue} type="audio/mp3" />
          </audio> */}
        </div>
      </div>

     
    </DataSlider>
  )
}
export default JobsRow
