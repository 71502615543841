import React from 'react'

import './KidsRetellingContainer.css';
import ContextCard from '../KidsRetellingCard/ContextCard';


function KidsRetellingContainer(props) {
  return (
   <div className='kids-retelling-container'>
    <ContextCard
          title="My pet"
          keyWord1="a pet"
          keyWord2="fluffy"
          keyWord3="to take turns"
          keyWord4="to feed"
          keyWord5="a cage"
          image="contextCard__image contextCard__image_1"
          text=" This is my pet rabbit. His name is Mr. Grey. He's cute and fluffy.
         He lives in our garden in the summer and in its cage in our living room in the winter.
         He likes carrots and cabbages, of course. My brother and I take turns to feed him. Mr Grey's six months old. 
         My parents presented him to me for my birthday and I was really happy. He likes running in the grass. I&nbsp;think he is my friend.
         "
        />

        <ContextCard
          title="My hobby"
          keyWord1="a patio"
          keyWord2="a bellflower"
          keyWord3="an orchid"
          keyWord4="to own"
          keyWord5="a florist"
          image="contextCard__image contextCard__image_2"
          text="Hi there! My name's Alice. As you see, my hobby's planting.
         I like flowers and I think that all plants're beautiful.
         I grow different flowers in my patio. I have got red roses, blue bellflowers and orange orchids.
         My mother teaches me how to look after them. She's a florist and owns a flower shop.
         She can make various bunches and floral ornaments. I sometimes help her in her shop."
        />

        <ContextCard
          title="Camping"
          keyWord1="at the campsite"
          keyWord2="crystal clear"
          keyWord3="over a fire"
          keyWord4="a tent"
          keyWord5="a peer"
          image="contextCard__image contextCard__image_3"
          text="Last August, my parents and I went camping.
         We stayed at the campsite near the crystal clear lake in the pine forest.
         We walked all days long, slept in the tents and cooked our food over a fire.
         I made friends with two peers, John and Kate. We played volleyball and cards.
          My mum took plenty of photos and my dad caught some fishes.
          I will never forget that summer. "
        />

        <ContextCard
          title="A present for Mark"
          keyWord1="a second cousin"
          keyWord2="as well"
          keyWord3="a guest"
          keyWord4="pocket money"
          keyWord5="memorable"
          image="contextCard__image contextCard__image_5"
          text="Mark is my second cousin and my best friend, as well.
         Next Sunday, he will be a birthday boy.
         He's having a party. There will be a lot of guests.
         I'm going to visit him and I want to present him something special and memorable. 
         I have some pocket money. So, I am planning to buy him a new kite in the local toy shop. He adores kites of all colours and shapes.
         I hope he'll like that gift."
        />

        <ContextCard
          title=" A day out"
          keyWord1="to show round"
          keyWord2="a rollercoaster"
          keyWord3="a maze"
          keyWord4="breathtaking"
          keyWord5="marvellous"
          image="contextCard__image contextCard__image_7"
          text="When my family's friends came to our city, we decided to show them round and invited them to the theme park.
          It was unforgettable. We took a ride on the Ferries wheel and the rollercoaster.
           To be honest, it was a bit scary yet exciting.
          Then we visited the haunted house and the mirror maze.
          The atmosphere was amazing and breathtaking.
          We finished our day walking near the marvellous pond and feeding white swans."
        />

        <ContextCard
          title="My grandparents' house"
          keyWord1="in the suburbs"
          keyWord2="a lawn"
          keyWord3="neat"
          keyWord4="to mow"
          keyWord5="an attic"
          image="contextCard__image contextCard__image_4"
          text="My grandparents live in the suburbs.
           They've got an attractive house with a big terrace and a fruit garden behind it.
           I have my bedroom in the attic. It's quite small, but cozy. 
            My grandpa keeps the lawn in front of the house neat. He mows it every week.
             I often visit them on my holidays and help my grandma to pick fruits or rake leaves.
             In the evening, we get together outdoors and have a barbecue. "
        />
        <ContextCard
          title=" Our Christmas tree"
          keyWord1="joyful"
          keyWord4="to decorate"
          keyWord3="tinsels"
          keyWord2="a hope"
          keyWord5="look like a picture"
          image="contextCard__image contextCard__image_6"
          text="Christmas is a family holiday which's been always important for me. It's joyful, full of fun, happiness and good hopes.
           In my country, people celebrate it on the seventh of January. I like decorating our Christmas tree together with my mum.
            We have a tradition to buy some new decorations: tinsels, balls and jingle bells. We create a new design every year. My favourite colours are red and white.
            It always looks like a picture."
        />
    </div>
  )
  
}

export default KidsRetellingContainer
