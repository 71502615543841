import React from 'react'

import './SecondaryFooter.css'

function SecondaryFooter() {
  return (
    <footer className="secondary-footer section">
      <div className="section-container t-b-paddings">
      <div className="secondary-footer__info">
        <p  translate="no" className="secondary-footer__info-text">
          &copy; 2023-2024 Study with Tatiana Parfe
        </p>
        <p className="secondary-footer__info-text">+7 (926) 33 77 444</p>
        <p className="secondary-footer__info-text">Парфенюк Т.В.</p>
        </div>
      </div>
    </footer>
  )
}

export default SecondaryFooter
